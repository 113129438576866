import { action } from "typesafe-actions";
import { GPListBulkDeletionState } from "../Dto/GeneralPractitionerList/GeneralPractitionerList";
import {
  GeneralPractitioner,
  ServiceArea,
  AllowedServiceArea,
  GeneralPractitionerListLicense,
  GeneralPractitionerList
} from "../../api_client/medre_api";

export const SET_GP_LIST = "SET_GP_LIST";
export const CLEAR_GP_LIST = "CLEAR_GP_LIST";
export const SET_LICENSES = "SET_LICENSES";
export const SET_SERVICE_AREA = "SET_SERVICE_AREA";
export const SET_ALLOWED_SERVICE_AREAS = "SET_ALLOWED_SERVICE_AREAS";
export const SET_GENERAL_PRACTITIONER = "SET_GENERAL_PRACTITIONER";
export const SET_GP_LIST_DELETIONS = "SET_GP_LIST_DELETIONS";
export const CLEAR_GP_LIST_DELETIONS = "CLEAR_GP_LIST_DELETIONS";

export const generalPractitionerListActions = {
  setList: (list: GeneralPractitionerList) => action(SET_GP_LIST, list),
  clearList: () => action(CLEAR_GP_LIST),

  setLicenses: (licenses?: GeneralPractitionerListLicense[]) =>
    action(SET_LICENSES, licenses),

  setServiceArea: (serviceArea?: ServiceArea) =>
    action(SET_SERVICE_AREA, serviceArea),

  setAllowedServiceAreas: (allowedServiceAreas?: AllowedServiceArea[]) =>
    action(SET_ALLOWED_SERVICE_AREAS, allowedServiceAreas),

  setGeneralPractitioner: (gp?: GeneralPractitioner) =>
    action(SET_GENERAL_PRACTITIONER, gp),

  setGPListBulkDeletions: (candidates: Partial<GPListBulkDeletionState>) =>
    action(SET_GP_LIST_DELETIONS, candidates),

  clearGPListBulkDeletions: () => action(CLEAR_GP_LIST_DELETIONS)
};
