import React, { useCallback, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { ReactTable, ReactTableColumn, ReactTableExpander } from "../../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { useMobile } from "../../../../Hook/useMobile";
import { useGPListTable } from "../useGPListTable";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import PortalGPListEmployeeModal from "../../../Portal/GeneralPractitioners/Modals/Employee";
import useModal from "../../../../Hook/useModal";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { getFullName } from "../../../../Util/PersonUtils";
import { usePortal } from "../../../../Hook/usePortal";
import { EmployeeRoleFM } from "../../../../Messages/EmployeeFM";
import { GPListDeleteRowButton } from "./GPListDeleteRowButton";
import {
  useGeneralPractitionerListDeletionsSelector
} from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import classNames from "classnames";
import { GeneralPractitionerListEmployee } from "../../../../../api_client/medre_api";
import { GPListModifyRowButton } from "./GPListModifyRowButton";

const headers = {
  fullName: (
    <FormattedMessage
      id="GPListDetailedTableHeader.fullName"
      defaultMessage="Nimi"
    />
  ),
  code: (
    <FormattedMessage
      id="GPListDetailedTableHeader.occupationCode"
      defaultMessage="Kood"
    />
  ),
  role: (
    <FormattedMessage
      id="GPListDetailedTableHeader.role"
      defaultMessage="Roll"
    />
  ),
  speciality: (
    <FormattedMessage
      id="GPListEmployeesTableHeader.speciality"
      defaultMessage="Eriala"
    />
  )
};

interface Props {
  publicView?: boolean;
}

export const GPListEmployees = ({ publicView }: Props) => {
  const {
    list: { employees }
  } = useGeneralPractitionerList();
  const { employees: selectedEmployees = [] } =
    useGeneralPractitionerListDeletionsSelector();

  const isPortal = usePortal();
  const isMobile = useMobile();

  const [editingEmployee, setEditingEmployee] =
    useState<GeneralPractitionerListEmployee | null>(null);
  const employeeModal = useModal();
  const columns = useMemo(() => {
    const baseColumns: ReactTableColumn<GeneralPractitionerListEmployee>[] = [
      {
        id: "expander",
        accessor: "role",
        Header: headers.fullName,
        Cell: (cell) =>
          isMobile ? (
            <ReactTableExpander cell={cell}>
              <div>{getFullName(cell.row.original)}</div>
              <span className="font-weight-500">
                <EmployeeRoleFM role={cell.value} />
              </span>
            </ReactTableExpander>
          ) : (
            getFullName(cell.row.original)
          ),
        width: 50
      },
      {
        id: "occupationCode",
        accessor: "occupationCode",
        collapse: isMobile,
        Header: headers.code,
        Cell: (cell) =>
          isMobile ? (
            <>
              <div className="font-size-14">{headers.code}</div>
              <span className="font-weight-500">{cell?.value?.code}</span>
            </>
          ) : (
            cell?.value?.code ?? ''
          ),
        width: 50
      },
      {
        accessor: "specialities",
        collapse: isMobile,
        Header: headers.speciality,
        Cell: (cell) => {
          const specialities = cell.value
            .map((speciality) => `${speciality.code} ${speciality.name}`)
            .join(", ");
          return isMobile ? (
            <>
              <div className="font-size-14">{headers.speciality}</div>
              <span className="font-weight-500">{specialities}</span>
            </>
          ) : (
            specialities
          );
        },
        width: 0
      }
    ];

    if (!isMobile) {
      baseColumns.splice(1, 0, {
        id: "role",
        accessor: "role",
        Header: headers.role,
        Cell: ({ value: role }) => <EmployeeRoleFM role={role} />,
        width: 50
      });
    }

    if (isPortal && !publicView) {
      baseColumns.push({
        accessor: "id",
        collapse: isMobile,
        sticky: !isMobile,
        Cell: (cell) => (
          <>
            <GPListModifyRowButton index={cell.row.index}>
              {(modal) => (
                <PortalGPListEmployeeModal
                  open={modal.isOpen}
                  onClose={modal.handleClose}
                  employee={cell.row.original}
                />
              )}
            </GPListModifyRowButton>
            <GPListDeleteRowButton
              index={cell.row.index}
              rowId={cell.value}
              candidate="employees"
            />
          </>
        ),
        disableSortBy: true,
        width: 50
      });
    }

    return baseColumns;
  }, [isMobile, isPortal]);

  const table = useGPListTable<GeneralPractitionerListEmployee>({
    data: employees ? employees : [],
    columns
  });
  const handleCloseEmployeeModal = useCallback(() => {
    employeeModal.handleClose();
    setEditingEmployee(null);
  }, [employeeModal, setEditingEmployee]);

  return (
    <Card className="my-4 pb-1 p-0 p-md-4">
      {employeeModal.isOpen && (
        <PortalGPListEmployeeModal
          open={true}
          onClose={handleCloseEmployeeModal}
          employee={editingEmployee}
        />
      )}

      <div
        id={GENERAL_PRACTITIONERS_DETAILS_SECTIONS.EMPLOYEES.id}
        className="h2 d-flex justify-content-between pt-3 pr-3 pl-3 p-md-0"
      >
        <h2>{GENERAL_PRACTITIONERS_DETAILS_SECTIONS.EMPLOYEES.title}</h2>
        {isPortal && !publicView && (
          <div className="d-none d-sm-block">
            <PrimaryFormattedButton
              id="addServiceEmployee"
              idPrefix="sm"
              onClick={employeeModal.handleOpen}
            />
          </div>
        )}
      </div>

      {!publicView && (
        <div className="d-block d-sm-none pl-3">
          <PrimaryFormattedButton
            id="addServiceEmployees"
            idPrefix="xs"
            onClick={employeeModal.handleOpen}
          />
        </div>
      )}
      <div className="mt-3">
        <ReactTable<GeneralPractitionerListEmployee>
          table={table}
          getHeaderProps={(column) =>
            column.getHeaderProps(column.getSortByToggleProps())
          }
          rows={table.rows}
          getRowProps={(row) =>
            row.getRowProps({
              className: classNames({
                danger: selectedEmployees.includes(row.id)
              })
            })
          }
        />
      </div>
    </Card>
  );
};
