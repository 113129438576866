import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useGeneralPractitionerList from "../useGeneralPractitionerList";
import { WarningBadge } from "../../../../Component/Badge/WarningBadge";
import { getFullName } from "../../../../Util/PersonUtils";
import { isDateInFuture } from "../../../../Util/DateUtils";
import { formatDate } from "../../Application/OverView/ApplicationOverViewUtil";
import { useParishLabelsByServiceAreaIds } from "../../../../Address/addressSelector";
import { RootState } from "../../../../rootReducer";
import { useAuthorized } from "../../../../Hook/usePortal";
import {
  ServiceArea,
  GeneralPractitionerListLicense,
  GeneralPractitioner
} from "../../../../../api_client/medre_api";
import { BackButton } from "../../../../Component/Button/BackButton";

interface Props {
  modifiedServiceArea?: ServiceArea;
  modifiedLicenses?: GeneralPractitionerListLicense[];
}

export const GPListDetailedHeader: FC<Props> = ({
  modifiedServiceArea,
  modifiedLicenses
}) => {
  const isAuthorized = useAuthorized();
  const { list } = useGeneralPractitionerList();
  const {
    liquidationDate: gpListLiquidationDate,
    generalPractitioner,
    serviceArea,
    substitute: gpSubstitute
  } = list;
  const isPortal = useSelector((state: RootState) => state.config.isPortal);
  const gpFullName = getFullName(generalPractitioner as GeneralPractitioner);

  const filteredServiceAreaIds = useMemo(
    () =>
      modifiedServiceArea?.ids
        ? (modifiedServiceArea?.ids).filter(
            (id) => !(serviceArea?.ids).includes(id)
          )
        : [],
    [modifiedServiceArea, serviceArea]
  );
  const serviceAreaParish = useParishLabelsByServiceAreaIds(
    filteredServiceAreaIds
  );

  const licensesWithActivationDate = useMemo(() => {
    if (!modifiedLicenses) {
      return [];
    }

    return modifiedLicenses?.filter(
      (license) =>
        !!license?.activationDate && isDateInFuture(license.activationDate)
    );
  }, [modifiedLicenses]);

  const renderGPListInLiquidationWarning = () => {
    if (!gpListLiquidationDate || !isDateInFuture(gpListLiquidationDate)) {
      return null;
    }

    return (
      <span className="mb-1 mt-1 mr-3">
        <WarningBadge
          id={"gpListInLiquidation"}
          message="gpListInLiquidation"
        />
      </span>
    );
  };

  const renderGPListHasSubstituteWarning = () => {
    if (!gpSubstitute?.activationDate) {
      return null;
    }

    return (
      <span className="mb-1 mt-1 mr-3">
        <WarningBadge
          id={"gpListHasSubstitute"}
          message="gpListHasSubstitute"
        />
      </span>
    );
  };

  const renderGPListServiceAreaChanging = () => {
    if (
      filteredServiceAreaIds?.length === 0 ||
      !modifiedServiceArea ||
      !isDateInFuture(modifiedServiceArea.activationDate)
    ) {
      return null;
    }

    return (
      <span className="mb-1 mt-1 mr-3">
        <WarningBadge
          id={"gpListServiceAreaChanging"}
          message="gpListServiceAreaChanging"
          values={{
            serviceArea: serviceAreaParish,
            activationDate: formatDate(modifiedServiceArea.activationDate)
          }}
        />
      </span>
    );
  };

  const renderGPListLinksToNewLicense = () => {
    if (!licensesWithActivationDate?.length) {
      return null;
    }

    return licensesWithActivationDate.map((license) => {
      return (
        <span className="mb-1 mt-1 mr-3" key={license.number}>
          <WarningBadge
            id={"gpListLinksToNewLicense"}
            message="gpListLinksToNewLicense"
            values={{
              licenseNumber: license.number,
              activationDate: formatDate(license.activationDate)
            }}
          />
        </span>
      );
    });
  };

  return (
    <div className="my-4">
      <BackButton />
      <div className="d-flex flex-wrap align-items-center ">
        <h1 className="mb-0 mr-3">
          {gpFullName ? (
            <FormattedMessage
              id="GPListDetailedContainer.title"
              defaultMessage="Perearsti nimistu {code} {fullName} "
              values={{ code: list.code, fullName: gpFullName }}
            />
          ) : (
            <FormattedMessage
              id="GPListDetailedContainer.subTitleWithCode"
              defaultMessage="{code} Perearstita nimistu"
              values={{ code: list.code }}
            />
          )}
        </h1>
        {!isPortal && isAuthorized && (
          <div className="d-flex flex-wrap mb-0">
            {renderGPListInLiquidationWarning()}
            {renderGPListHasSubstituteWarning()}
            {renderGPListServiceAreaChanging()}
            {renderGPListLinksToNewLicense()}
          </div>
        )}
      </div>
    </div>
  );
};
