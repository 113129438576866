import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { ActivityLicenseContactInfoForm } from "../../Shared/ActivityLicense/BusinessData/ActivityLicenseContactInfoForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { ContactInfoForm } from "../../../Dto/ContactInfo/ContactInfoForm";
import {activityLicenseApplicationActions} from "../../../ActivityLicense/activityLicenseApplicationActions";
import { SearchBusiness } from "./SearchBusiness";
import { SearchRepresentative } from "./SearchRepresentative";
import { BusinessRegistryInfoCard } from "../../Shared/ActivityLicense/BusinessData/BusinessRegistryInfoCard";
import { SubmittingPersonCard } from "../../Shared/ActivityLicense/Overview/SubmittingPersonCard";
import {
  ActivityLicenseApplicationProceedingTypeEnum,
  ActivityLocation,
  ActivityLicenseApplication
} from "../../../../api_client/medre_api";
import { isLocationAddressField } from "../../../Dto/ActivityLicense/ActivityLicense";

interface Props {
  isContactInfoDisabled: boolean;
}

export const BusinessData = ({ isContactInfoDisabled }: Props) => {
  const dispatch = useDispatch();
  const activityLicense = useSelector(
    (state: RootState) => state.activityLicenseApplication
  );
  const [validContactInfo, setValidContactInfo] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(0);

  const isActivityLocationApplication =
    activityLicense.proceedingType ===
    ActivityLicenseApplicationProceedingTypeEnum.ActivityLocation;
  const companyInfo = isActivityLocationApplication
    ? activityLicense.activityLicense?.company
    : activityLicense.company;

  const updateContactInfo = useCallback(
    (field: keyof ContactInfoForm, value?: string) => {
      if (field === "email" || field === "phone") {
        dispatch(
          activityLicenseApplicationActions.updateCompanyContacts(field, value)
        );
      }
    },
    [dispatch]
  );

  const updateAddress = useCallback(
    (field: keyof ContactInfoForm, value?: string | boolean) => {
      if (isLocationAddressField(field)) {
        dispatch(
          activityLicenseApplicationActions.updateLocationInfo(
            0,
            field as keyof ActivityLocation,
            value
          )
        );
      }
    },
    [dispatch]
  );

  const setValidContactForm = useCallback((isAllowed: boolean) => {
    setValidContactInfo(isAllowed);
  }, []);

  useEffect(() => {
    if (formKey !== 0) {
      dispatch(
          activityLicenseApplicationActions.updateActivityLicense("submittingPersonFirstName", undefined)
      );
      dispatch(
          activityLicenseApplicationActions.updateActivityLicense("submittingPersonLastName", undefined)
      );
      dispatch(
          activityLicenseApplicationActions.updateActivityLicense("submittingPersonIdCode", undefined)
      );
      dispatch(
          activityLicenseApplicationActions.updateActivityLicense("submittingPersonRole", undefined)
      );
      updateContactInfo("fullAddress", undefined);
      updateContactInfo("zipCode", undefined);
    }
  }, [formKey]);

  useEffect(() => {
    if (
      validContactInfo &&
      activityLicense.submittingPersonFirstName &&
      activityLicense.submittingPersonLastName &&
      activityLicense.submittingPersonIdCode
    ) {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          true
        )
      );
    } else {
      dispatch(
        activityLicenseApplicationActions.updateActivityLicense(
          "saveAllowed" as keyof ActivityLicenseApplication,
          false
        )
      );
    }
  }, [
    dispatch,
    validContactInfo,
    activityLicense.submittingPersonFirstName,
    activityLicense.submittingPersonLastName,
    activityLicense.submittingPersonIdCode
  ]);

  return (
    <div className="main-column">
      <h3 className="application-title fw-normal">
        <FormattedMessage
          id="activityLicense.businessData"
          defaultMessage="Ettevõtte andmed"
        />
      </h3>
      <p>
        <FormattedMessage
          id="activityLicense.checkBusinessData"
          defaultMessage="Kontrolli ettevõtte andmeid, määra tegevuskoha info ning klõpsa “Edasi”."
        />
      </p>
      <SearchBusiness
        registryCode={companyInfo?.registryCode}
        isDisabled={isActivityLocationApplication}
        prevKey={formKey}
        setFormKey={setFormKey}
      />
      {companyInfo?.registryCode && (
        <>
          <BusinessRegistryInfoCard
            name={companyInfo?.name}
            address={companyInfo?.address}
            registryCode={companyInfo?.registryCode}
          />
          <SearchRepresentative
            formKey={formKey}
            representatives={companyInfo?.representatives}
            submittingPersonIdCode={activityLicense.submittingPersonIdCode}
          />
        </>
      )}
      {activityLicense.submittingPersonFirstName &&
        activityLicense.submittingPersonLastName && (
          <SubmittingPersonCard
            personName={
              activityLicense.submittingPersonFirstName +
              " " +
              activityLicense.submittingPersonLastName
            }
            personIdCode={activityLicense.submittingPersonIdCode}
          />
        )}
      <ActivityLicenseContactInfoForm
        formKey={formKey}
        isContactInfoDisabled={isContactInfoDisabled}
        updateContactInfo={updateContactInfo}
        email={companyInfo?.email}
        phone={companyInfo?.phone}
        location={activityLicense.locations ? activityLicense.locations[0] : {}}
        updateAddress={updateAddress}
        setIsFormValid={setValidContactForm}
        isActivityLocationApplication={isActivityLocationApplication}
      />
    </div>
  );
};
