import React, { PropsWithChildren, useMemo } from "react";
import { useFlexLayout, useSortBy, useTable, Column } from "react-table";

import { GpListHistoryTypes } from "./GpListHistoryTypes";
import {
  GeneralPractitionerListHistoryFamilyDoctor,
  GeneralPractitionerListHistorySubstitute,
  GeneralPractitionerListHistoryMaxPatientsSize,
  GeneralPractitionerListHistoryServiceArea,
  GeneralPractitionerListHistoryType,
  GeneralPractitionerListHistoryLicense,
  GeneralPractitionerListHistoryLicenseAddress,
  GeneralPractitionerListHistorySubstitutes,
  GeneralPractitionerListHistoryEmployees
} from "../../../../../Dto/GeneralPractitionerList/GeneralPractitionerListHistoryLog";
import { formatDate } from "../../../../Shared/Application/OverView/ApplicationOverViewUtil";
import { useFetchGPListHistory } from "./useFetchGPListHistory";
import { Loader } from "../../../../../Component/Loader/Loader";
import { AuditLogItem } from "../../../../../../api_client/medre_api";
import { getFullName } from "../../../../../Util/PersonUtils";

interface Props {
  type: GpListHistoryTypes;
}

const COLUMNS_FAMILY_DOCTOR: Column<GeneralPractitionerListHistoryFamilyDoctor>[] =
  [
    {
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ value }) => formatDate(value)
    },
    {
      id: "codeGP",
      Header: "Kood",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => value.codeGP
    },
    {
      id: "name",
      Header: "Nimi",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => getFullName(value)
    },
    {
      id: "startDate",
      Header: "Töötamise algus",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => formatDate(value.activationDate)
    },
    {
      id: "endDate",
      Header: "Töötamise lõpp",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.deactivationDate)
    }
  ];

const COLUMNS_SUBSTITUTE: Column<GeneralPractitionerListHistorySubstitute>[] = [
  {
    id: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => formatDate(value)
  },
  {
    id: "codeGP",
    Header: "Kood",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => value.medreCode
  },
  {
    id: "name",
    Header: "Nimi",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => getFullName(value)
  },
  {
    id: "activationDate",
    Header: "Asendamise algus",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => formatDate(value.activationDate)
  },
  {
    id: "deactivationDate",
    Header: "Asendamise lõpp",
    accessor: "metadata",
    Cell: ({ value }) => formatDate(value.deactivationDate)
  }
];

const COLUMNS_SUBSTITUTES: Column<GeneralPractitionerListHistorySubstitutes>[] =
  [
    {
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ value }) => formatDate(value)
    },
    {
      id: "codeGP",
      Header: "Kood",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => value.codeGP
    },
    {
      id: "name",
      Header: "Nimi",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => getFullName(value)
    },
    {
      id: "activationDate",
      Header: "Asendamise algus",
      accessor: "metadata",
      width: 50,
      Cell: ({ value }) => formatDate(value.activationDate)
    },
    {
      id: "deactivationDate",
      Header: "Asendamise lõpp",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.deactivationDate)
    }
  ];

const COLUMNS_LICENSE: Column<GeneralPractitionerListHistoryLicense>[] = [
  {
    id: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => formatDate(value)
  },
  {
    id: "company",
    Header: "Teenuse osutaja",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => value.companyName
  },
  {
    id: "licenseNumber",
    Header: "Tegevusload",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => value.licenseNumber
  },
  {
    id: "startDate",
    Header: "Kehtivuse algus",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => formatDate(value.activationDate)
  },
  {
    id: "endDate",
    Header: "Kehtivuse lõpp",
    accessor: "metadata",
    Cell: ({ value }) => formatDate(value.deactivationDate)
  }
];

const COLUMNS_MAX_PATIENTS_SIZE: Column<GeneralPractitionerListHistoryMaxPatientsSize>[] =
  [
    {
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ value }) => formatDate(value)
    },
    {
      width: 50,
      id: "activationDate",
      Header: "Kuupäev",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.activationDate)
    },
    {
      id: "presentValue",
      Header: "Piirsuurus",
      accessor: "metadata",
      Cell: ({ value }) => value.presentValue
    }
  ];

const COLUMNS_SERVICE_AREA: Column<GeneralPractitionerListHistoryServiceArea>[] =
  [
    {
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ value }) => formatDate(value)
    },
    {
      width: 50,
      id: "serviceArea",
      Header: "Teeninduspiirkond",
      accessor: "metadata",
      Cell: ({ value }) => value.serviceArea
    },
    {
      width: 50,
      id: "startDate",
      Header: "Määramise kuupäev",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.activationDate)
    },
    {
      id: "endDate",
      Header: "Eemaldamise kuupäev",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.deactivationDate)
    }
  ];

const COLUMNS_EMPLOYEES: Column<GeneralPractitionerListHistoryEmployees>[] = [
  {
    id: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => formatDate(value)
  },
  {
    id: "codeGP",
    Header: "Kood",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => value.medreCode
  },
  {
    id: "name",
    Header: "Nimi",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => getFullName(value)
  },
  {
    id: "role",
    Header: "Roll",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => value.role
  },
  {
    id: "activationDate",
    Header: "Töötamise algus",
    accessor: "metadata",
    width: 50,
    Cell: ({ value }) => formatDate(value.activationDate),
    disableResizing: true
  },
  {
    id: "endDate",
    Header: "Töötamise lõpp",
    accessor: "metadata",
    Cell: ({ value }) => formatDate(value.deactivationDate)
  }
];

const COLUMNS_LICENSE_ADDRESS: Column<GeneralPractitionerListHistoryLicenseAddress>[] =
  [
    {
      id: "createdAt",
      accessor: "createdAt",
      Cell: ({ value }) => formatDate(value)
    },
    {
      id: "licenseAddress",
      Header: "Aadress",
      accessor: "metadata",
      Cell: ({ value }) => value.licenseAddress
    },
    {
      id: "startDate",
      Header: "Kehtivuse algus",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.activationDate),
      disableResizing: true
    },
    {
      id: "endDate",
      Header: "Kehtivuse lõpp",
      accessor: "metadata",
      Cell: ({ value }) => formatDate(value.deactivationDate)
    }
  ];

const GPListHistoryTable = <T extends GeneralPractitionerListHistoryType>({
  type
}: PropsWithChildren<Props>) => {
  const { data, loading } = useFetchGPListHistory<T>(type);

  const columns = useMemo(() => {
    switch (type) {
      case GpListHistoryTypes.ChangeFamilyDoctor:
        return COLUMNS_FAMILY_DOCTOR;
      case GpListHistoryTypes.ChangeSubstituteDoctor:
        return COLUMNS_SUBSTITUTE;
      case GpListHistoryTypes.ChangeSubstitute:
        return COLUMNS_SUBSTITUTES;
      case GpListHistoryTypes.ChangeLicense:
        return COLUMNS_LICENSE;
      case GpListHistoryTypes.ChangeMaxPatientsSize:
        return COLUMNS_MAX_PATIENTS_SIZE;
      case GpListHistoryTypes.ChangeServiceArea:
        return COLUMNS_SERVICE_AREA;
      case GpListHistoryTypes.ChangeEmployees:
        return COLUMNS_EMPLOYEES;
      case GpListHistoryTypes.ChangeLicenseAddress:
        return COLUMNS_LICENSE_ADDRESS;
      default:
        return [];
    }
  }, [type]) as Column<T>[];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>(
      {
        columns,
        data: data as T[],
        initialState: {
          sortBy: [{ id: "createdAt", desc: true }],
          hiddenColumns: ["createdAt"]
        }
      },
      useSortBy,
      useFlexLayout
    );

  return (
    <div className="table-wrap d-flex flex-column">
      {loading ? (
        <Loader />
      ) : (
        <table
          {...getTableProps({
            className: "s-table user-applications-table"
          })}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              // tslint:disable-next-line:jsx-key
              <tr {...headerGroup.getHeaderGroupProps({ className: "m-0" })}>
                {headerGroup.headers.map((column) => (
                  // tslint:disable-next-line:jsx-key
                  <th
                    {...column.getHeaderProps({
                      className: "normal-column h-auto text-wrap"
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      // tslint:disable-next-line:jsx-key
                      <td
                        {...cell.getCellProps({
                          className: "normal-column h-auto text-wrap"
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GPListHistoryTable;
