import React, { ChangeEvent, MouseEvent, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Nav, NavItem } from "reactstrap";
import classNames from "classnames";

import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { ApplicationFilters } from "../../../Official/Search/Filters/ApplicationFilters";
import { ApplicantFilters } from "../../../Official/Search/Filters/ApplicantFilters";
import { ApplicationCertificateFilters } from "../../../Official/Search/Filters/ApplicationCertificateFilters";
import { filterSelectors } from "./filterStore";
import { filterActions } from "./filterActions";
import { searchResultsActions } from "../searchResultsActions";
import { ActiveFilters } from "./FilterTypes";
import { CompanyFilters } from "../../../Official/Search/Filters/CompanyFilters";
import { InsuranceContractFilters } from "../../../Official/Search/Filters/InsuranceContractFilters";
import { GeneralPractitionerListFilters } from "./GeneralPractitionerListFilters";
import {
  PublicGeneralPractitionerListFilters
} from "../../../Portal/Search/Filters/PublicGeneralPractitionerListFilters";
import { PublicActivityLicenseFilters } from "./ActivityLicense/PublicActivityLicenseFilters";
import { PublicHealthcareEmployeesFilters } from "../../../Portal/Search/Filters/PublicHealthcareEmployeesFilters";
import { ActivityLicenseApplicationFilters } from "../../../Official/Search/Filters/ActivityLicenseApplicationFilters";
import { usePortal } from "../../../../Hook/usePortal";
import { DecisionFilters } from "../../../Official/Search/Filters/DecisionFilters";
import { ProtectedFieldWrapper } from "../../../../Security/ProtectedFieldWrapper";
import { OfficialUserPrivilegesEnum } from "../../../../../api_client/medre_api";
import { AlertItem } from "../../../../Dto/Alert/AlertItem";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";
import { RootState } from "../../../../rootReducer";
import { OfficialActivityLicenseFilters } from "./ActivityLicense/OfficialActivityLicenseFilters";

interface Props {
  onSubmit: () => void;
  onExport: () => Promise<void | {
    type: "ADD_ALERT";
    payload: AlertItem;
  }>;
  onKraExport: () => Promise<void | {
    type: "ADD_ALERT";
    payload: AlertItem;
  }>;
  hideFilterSelector?: boolean;
}

export const Filters = ({
  hideFilterSelector,
  onSubmit,
  onExport,
  onKraExport
}: Props) => {
  const dispatch = useDispatch();
  const isPortal = usePortal();
  const filterType = useSelector(filterSelectors.selectFilterType);
  const isInsuranceEnabled = useSelector((state: RootState) => state.featureFlag.featureFlags.INSURANCE_V1);
  const exportButtonAvailable = useMemo(
    () =>
      (filterType === ActiveFilters.ActivityLicenses && !isPortal) ||
      filterType === ActiveFilters.Applicant ||
      filterType === ActiveFilters.Company,
    [filterType, isPortal]
  );

  const handleClear = useCallback(() => {
    switch (filterType) {
      case ActiveFilters.Applicant:
        dispatch(filterActions.resetAllApplicantFilters());
        break;
      case ActiveFilters.Application:
        dispatch(filterActions.resetAllApplicationFilters());
        break;
      case ActiveFilters.ActivityLicenseApplications:
        dispatch(filterActions.resetAllActivityLicenseApplicationFilters());
        break;
      case ActiveFilters.ApplicationCertificate:
        dispatch(filterActions.resetAllApplicationCertificateFilters());
        break;
      case ActiveFilters.Company:
        dispatch(filterActions.resetAllCompanyFilters());
        break;
      case ActiveFilters.GeneralPractitionerList:
        dispatch(filterActions.resetAllGeneralPractitionerListFilters());
        break;
      case ActiveFilters.PublicGeneralPractitionerList:
        dispatch(filterActions.resetAllPublicGeneralPractitionerListFilters());
        break;
      case ActiveFilters.PublicActivityLicenses:
        dispatch(filterActions.resetAllActivityLicenseFilters());
        break;
      case ActiveFilters.ActivityLicenses:
        dispatch(filterActions.resetAllOfficialActivityLicenseFilters());
        break;
      case ActiveFilters.PublicHealthcareEmployees:
        dispatch(filterActions.resetAllHealthcareEmployeesFilters());
        break;
      case ActiveFilters.DecisionTHT:
        dispatch(filterActions.resetAllDecisionTHTFilter());
        break;
      case ActiveFilters.DecisionLicense:
        dispatch(filterActions.resetAllDecisionLicenseFilter());
        break;
      case ActiveFilters.InsuranceContract:
        dispatch(filterActions.resetAllInsuranceContractFilters());
        break;
      default:
        break;
    }
  }, [filterType, dispatch]);

  const tabs = useMemo<{id: string; value: ActiveFilters | ActiveFilters[]; label: string}[]>(() => {
    const filterTabs = [
      {
        id: "applicantFilters",
        value: ActiveFilters.Applicant,
        label: "THT/taotleja"
      },
      {
        id: "applicationFilters",
        value: ActiveFilters.Application,
        label: "THT taotlused"
      },
      {
        id: "certificateFilters",
        value: ActiveFilters.ApplicationCertificate,
        label: "Kutsekvalifikatsiooni tõendid"
      },
      {
        id: "activityLicenseFilters",
        value: ActiveFilters.ActivityLicenses,
        label: "Tegevusload"
      },
      {
        id: "activityLicenseApplicationFilters",
        value: ActiveFilters.ActivityLicenseApplications,
        label: "Tegevuslubade taotlused"
      },
      {
        id: "generalPractitionerListFilters",
        value: ActiveFilters.GeneralPractitionerList,
        label: "Perearsti nimistud"
      },
      {
        id: "decisionFilters",
        value: [ActiveFilters.DecisionTHT, ActiveFilters.DecisionLicense],
        label: "Otsused"
      },
      {
        id: "companyFilters",
        value: ActiveFilters.Company,
        label: "Ettevõtted"
      }
    ];
      
    if (isInsuranceEnabled) {
      filterTabs.push({id: "insuranceContractFilters", value: ActiveFilters.InsuranceContract, label: "Kindlustus"});
    }

    return filterTabs;
  },
  [isInsuranceEnabled]
  );

  const handleFilterTypeChange = (event: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement>): void => {
    const value = event.currentTarget.value as ActiveFilters;
    dispatch(searchResultsActions.setData([], value));
    dispatch(searchResultsActions.setRowCount(0));
    dispatch(searchResultsActions.setPageCount(0));
    dispatch(searchResultsActions.setFilters(null));
    dispatch(filterActions.setFilterType(value));
  };

  return (
    <div className="filters-container">
      { hideFilterSelector ? null : (
        <Nav tabs>
          { tabs.map(({ id, value, label }) => {
            const valueIsArray = Array.isArray(value);
            const dataValue = valueIsArray ? value[0] : value;
            const isActive = valueIsArray
              ? value.includes(filterType)
              : value === filterType;
            return (
              <NavItem key={id} className="w-auto">
                <button
                  className={classNames("nav-link", {
                    active: isActive,
                    "bg-transparent": !isActive,
                    "bg-white": isActive
                  })}
                  onClick={handleFilterTypeChange}
                  value={dataValue}
                >
                  { label }
                </button>
              </NavItem>
            );
          }) }
        </Nav>
      ) }
      <Card className="filters-card">
        { filterType === ActiveFilters.Applicant && <ApplicantFilters /> }
        { filterType === ActiveFilters.Application && <ApplicationFilters /> }
        { filterType === ActiveFilters.ApplicationCertificate && (
          <ApplicationCertificateFilters />
        ) }
        { filterType === ActiveFilters.PublicActivityLicenses && <PublicActivityLicenseFilters />}
        { filterType === ActiveFilters.ActivityLicenses && <OfficialActivityLicenseFilters />}
        { filterType === ActiveFilters.ActivityLicenseApplications && (
          <ActivityLicenseApplicationFilters />
        ) }
        { filterType === ActiveFilters.GeneralPractitionerList && (
          <GeneralPractitionerListFilters />
        ) }
        { [ActiveFilters.DecisionTHT, ActiveFilters.DecisionLicense].includes(
          filterType
        ) && (
          <DecisionFilters handleFilterTypeChange={handleFilterTypeChange} />
        ) }
        { filterType === ActiveFilters.Company && <CompanyFilters /> }

        { filterType === ActiveFilters.PublicGeneralPractitionerList && (
          <PublicGeneralPractitionerListFilters />
        ) }
        { filterType === ActiveFilters.PublicHealthcareEmployees && (
          <PublicHealthcareEmployeesFilters />
        ) }
        { filterType === ActiveFilters.InsuranceContract && <InsuranceContractFilters /> }
        <div className="button-wrapper mt-4 d-flex flex-column flex-sm-row">
          <PrimaryFormattedButton
            id="search"
            onClick={onSubmit}
            className="mr-3 mb-3"
          />
          <SecondaryFormattedButton
            id="clearAllValues"
            onClick={handleClear}
            className="mr-3 mb-3"
          />
          { exportButtonAvailable && (
            <ProtectedComponent
              allowedRoles={[
                OfficialUserPrivilegesEnum.Proceede,
                OfficialUserPrivilegesEnum.Sign
              ]}
            >
              <PrimaryFormattedButton
                id="exportToCsv"
                onAsyncClick={onExport}
                className="mr-3 mb-3"
              />
            </ProtectedComponent>
          ) }
          {/* Commented out because of https://smjira.sm.ee/browse/MEDRE-2521*/ }
          {/* {filterType === ActiveFilters.Applicant && (*/ }
          {/*  <ProtectedFieldWrapper*/ }
          {/*    allowedRoles={[OfficialUserPrivilegesEnum.Sign]}*/ }
          {/*  >*/ }
          {/*    <PrimaryFormattedButton*/ }
          {/*      id="exportThtKraCsv"*/ }
          {/*      onAsyncClick={onKraExport}*/ }
          {/*      className="mb-3"*/ }
          {/*    />*/ }
          {/*  </ProtectedFieldWrapper>*/ }
          {/* )}*/ }
          { filterType === ActiveFilters.ActivityLicenses && (
            <ProtectedFieldWrapper
              allowedRoles={[OfficialUserPrivilegesEnum.Sign]}
            >
              <PrimaryFormattedButton
                id="exportTtoKraCsv"
                onAsyncClick={onKraExport}
                className="mb-3"
              />
            </ProtectedFieldWrapper>
          ) }
        </div>
      </Card>
    </div>
  );
};
