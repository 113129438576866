import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../rootReducer";
import { Option, SingleSelect } from "../../../../../Component/Select/SingleSelect";
import {
  ActivityLicenseBusinessArea,
  AvalikAadressideAndmeteTeenusApiFactory as publicAddressDataAPI,
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI,
  County,
  Service
} from "../../../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../../../api";
import { filterSelectors } from "../filterStore";
import { filterActions, FilterItemData } from "../filterActions";
import { Option as filterOption } from "react-select/src/filters";
import { FilterColumn, FilterContainer, FilterField } from "../../../../../Component/Filter/Filter";
import { Loader } from "../../../../../Component/Loader/Loader";
import { FormattedMessage } from "react-intl";
import { Input } from "reactstrap";
import { MultiSelect } from "../../../../../Component/Select/MultiSelect";
import { activityLicenseInsuranceContractStatuses } from "../FilterUtils";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import { Item, makeOptions, selectProps, statuses } from "./ActivityLicenseFIltersCommons";

export const OfficialActivityLicenseFilters = () => {
  const dispatch = useDispatch();
  const isInsuranceEnabled = useSelector((state: RootState) => state.featureFlag.featureFlags.INSURANCE_V1);

  const [loading, setLoading] = useState(true);
  const [serviceOptions, setServiceOptions] = useState<Array<Option>>([]);
  const [businessAreaOptions, setBusinessAreaOptions] = useState<Array<Option>>([]);
  const [hospitalAreaOptions, setHospitalAreaOptions] = useState<Array<Option>>([]);
  const [countyOptions, setCountyOptions] = useState<Array<Option>>([]);
  const [isSelectedServices, setIsSelectedServices] = useState(false);

  const state = useSelector(
    filterSelectors.selectOfficialActivityLicenseFilters,
    shallowEqual
  );

  const loadServices = (): void => {
    publicActivityPermitAPI(undefined, getBaseUrl(), API)
      .getServices({
        withCredentials: true
      })
      .then((
        { data: services }
      ) => {
        setServiceOptions(makeOptions(services));
      });
  };

  const loadClassifiersSpecificServices = (businessAreaIds: string[]): void => {
    publicActivityPermitAPI(undefined, getBaseUrl(), API)
      .getBusinessAreaClassifiersServices(businessAreaIds, {
        withCredentials: true
      })
      .then((
        { data: services }
      ) => {
        setServiceOptions(makeOptions(services));
      });
  };

  const fetchDynamicServiceOptions = (businessAreaIds: string[]): void =>
    businessAreaIds.length === 0 ? loadServices() : loadClassifiersSpecificServices(businessAreaIds);

  const processResponses = (
    services: Service[],
    regularAreas: ActivityLicenseBusinessArea[] | undefined,
    hospitalTypeAreas: ActivityLicenseBusinessArea[] | undefined,
    counties: County[]): void => {
    setServiceOptions(makeOptions(services));
    setBusinessAreaOptions(makeOptions(regularAreas as Item[]));
    setHospitalAreaOptions(makeOptions(hospitalTypeAreas as Item[]));
    setCountyOptions(makeOptions(counties as Item[]));

    if (state.businessAreaIds) {
      const mappedIds = state.businessAreaIds.map(id => id.value);
      fetchDynamicServiceOptions(mappedIds);
    }

    if (state.serviceIds) {
      setIsSelectedServices(true);
    }
  };

  useEffect(() => {
    Promise.all([
      publicActivityPermitAPI(undefined, getBaseUrl(), API).getServices({
        withCredentials: true
      }),
      publicActivityPermitAPI(
        undefined,
        getBaseUrl()
      ).getBusinessAreaClassifiers({
        withCredentials: true
      }),
      publicAddressDataAPI(undefined, getBaseUrl(), API).getCounties()
    ])
      .then(
        ([
          { data: services },
          {
            data: { regularAreas, hospitalTypeAreas }
          },
          { data: counties }
        ]) => {
          processResponses(services, regularAreas, hospitalTypeAreas, counties);
        }
      )
      .finally(() => setLoading(false));
  }, []);

  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setOfficialActivityLicensesFilter({ key, data }));
    },
    [dispatch]
  );

  const handleChangeSelect = useCallback(
    (option: Option, name: string) => {
      const value = option?.value ?? null;
      set(name, value);
    },
    [set]
  );

  const handleChangeBusinessAreaIds = (options: Option[]): void => {
    if (options) {
      fetchDynamicServiceOptions(options.map((option) => option.value));

      if (isSelectedServices) {
        state.serviceIds = [];
        setIsSelectedServices(false);
      }
    } else {
      loadServices();
    }
  };

  const handleChangeServiceIds = (): void => {
    setIsSelectedServices(true);
  };

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );

  const handleChangeMultiSelect = useCallback(
    (options: Option[], name?: string) => {
      if (!name) {
        return;
      }

      switch (name) {
        case "businessAreaIds":
          handleChangeBusinessAreaIds(options);
          break;
        case "serviceIds":
          handleChangeServiceIds();
          break;
      }

      set(name!, options);
    },
    [set, isSelectedServices, state]
  );

  const serviceFilterOptions = (option: filterOption, rawInput: string) =>
    !!option.label.toLowerCase().match(rawInput.toLowerCase());

  return (
    <FilterContainer>
      {loading && <Loader absolute />}
      <FilterColumn>
        <FilterField
          id="activityLicenseFiltersCompanyName"
          label={
            <FormattedMessage
              id="filters.companyLabel"
              defaultMessage="Teenuse osutaja"
            />
          }
          field={
            <Input
              id="activityLicenseFiltersCompanyName"
              value={state.companyName}
              onChange={handleChangeInput}
              name="companyName"
              placeholder="Ettevõtte nimi"
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersBusinessArea"
          label={
            <FormattedMessage
              id="filters.businessArea"
              defaultMessage="Tegevusala"
            />
          }
          field={
            <MultiSelect
              inputId="activityLicenseFiltersBusinessArea"
              name="businessAreaIds"
              value={state.businessAreaIds ?? []}
              options={businessAreaOptions}
              handleOptionsChange={handleChangeMultiSelect}
              {...selectProps}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersServiceId"
          label={
            <FormattedMessage id="filters.service" defaultMessage="Teenus" />
          }
          field={
            <MultiSelect
              inputId="activityLicenseFiltersServiceId"
              name="serviceIds"
              value={state.serviceIds ?? []}
              options={serviceOptions}
              handleOptionsChange={handleChangeMultiSelect}
              {...selectProps}
              isSearchable
              filterOptions={serviceFilterOptions}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersCounty"
          label={
            <FormattedMessage id="filters.county" defaultMessage="Maakond" />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersCounty"
              name="countyId"
              options={countyOptions}
              handleOptionChange={handleChangeSelect}
              value={state.countyId}
              {...selectProps}
            />
          }
        />
      </FilterColumn>

      <FilterColumn>
        <FilterField
          id="activityLicenseFiltersActivityLicenseNumber"
          label={
            <FormattedMessage
              id="filters.activityLicenseNumber"
              defaultMessage="Tegevusloa number"
            />
          }
          field={
            <Input
              id="activityLicenseFiltersActivityLicenseNumber"
              value={state.code}
              onChange={handleChangeInput}
              name="code"
              placeholder="Tegevusloa number"
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersHospitalId"
          label={
            <FormattedMessage
              id="filters.hospitalType"
              defaultMessage="Haigla liik"
            />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersHospitalId"
              name="hospitalId"
              options={hospitalAreaOptions}
              handleOptionChange={handleChangeSelect}
              value={state.hospitalId}
              {...selectProps}
              isSearchable
              filterOptions={serviceFilterOptions}
            />
          }
        />

        <FilterField
          id="activityLicenseFiltersValidity"
          label={
            <FormattedMessage id="filters.validity" defaultMessage="Staatus" />
          }
          field={
            <SingleSelect
              inputId="activityLicenseFiltersValidity"
              name="status"
              options={statuses}
              handleOptionChange={handleChangeSelect}
              value={state.status}
              {...selectProps}
            />
          }
        />

        {/* Remove isInsuranceEnabled check once 'vastutuskindlustus' in live */}
        { isInsuranceEnabled &&
            <FilterField
              id="activityLicenseFiltersInsuranceContractStatus"
              label={<FormattedMessage id="filters.insuranceContract" defaultMessage="Kindlustus" />}
              field={
                <MultiSelect
                  inputId="activityLicenseFiltersInsuranceContractStatus"
                  name="insuranceContractStatus"
                  options={activityLicenseInsuranceContractStatuses}
                  handleOptionsChange={(e) => set("insuranceContractStatuses", e)}
                  value={state.insuranceContractStatuses ?? []}
                  placeholder={<GeneralFM id="selectPlaceholderAll"/>}
                />
              }
            /> }
      </FilterColumn>
      <FilterColumn />
    </FilterContainer>
  );
};