import React, { FC, useEffect, useMemo } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { TableFooter } from "./TableFooter";
import { ReactTable } from "../../../../Component/Table/ReactTable";
import { usePortal } from "../../../../Hook/usePortal";
import { useDispatch, useSelector } from "react-redux";
import { searchResultsActions } from "../searchResultsActions";
import { ActivityLicense } from "../../../../../api_client/medre_api";
import { useMobile } from "../../../../Hook/useMobile";
import { RootState } from "../../../../rootReducer";
import {
  activityLicenseOfficialColumns,
  activityLicensePortalColumns,
  activityLicensePortalMobileColumns
} from "./ResultsTableColumns/ActivityLicenseResultsColumns";
import {
  temporaryActivityLicenseOfficialColumns,
  temporaryActivityLicensePortalColumns, temporaryActivityLicensePortalMobileColumns
} from "./ResultsTableColumns/TemporaryActivityLicenseResultsColumns";

interface Props {
  data: ActivityLicense[];
  pageCount: number;
  pageIndex: number;
}

export const ActivityLicenseResults: FC<Props> = ({
  data,
  pageCount,
  pageIndex
}) => {
  const isPortal = usePortal();
  const isMobile = useMobile();
  const isInsuranceEnabled = useSelector((state: RootState) => state.featureFlag.featureFlags.INSURANCE_V1);

  // Remove check 'isInsuranceEnabled' once 'vastutuskindlustus' in live
  const columns = useMemo(() => {
    if (!isInsuranceEnabled) {
      return isPortal
        ? isMobile
          ? activityLicensePortalMobileColumns
          : activityLicensePortalColumns
        : activityLicenseOfficialColumns;
    } else {
      return isPortal
        ? isMobile
          ? temporaryActivityLicensePortalMobileColumns
          : temporaryActivityLicensePortalColumns
        : temporaryActivityLicenseOfficialColumns;
    }
  }, [isPortal, isMobile, isInsuranceEnabled]);

  const table = useTable<ActivityLicense>(
    {
      columns,
      data,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      autoResetSortBy: false,
      disableSortBy: isPortal
    },
    useSortBy,
    usePagination
  );

  const {
    previousPage,
    nextPage,
    gotoPage,
    canPreviousPage,
    canNextPage,
    state: { sortBy }
  } = table;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchResultsActions.setSortBy(sortBy));
  }, [sortBy, dispatch]);

  return (
    <div className="table-wrap">
      <ReactTable<ActivityLicense>
        table={table}
        rows={table.rows}
        getHeaderProps={(column) =>
          column.getHeaderProps(column.getSortByToggleProps())
        }
        className="border-0"
        square
      />
      <div className="border-bottom" />
      <TableFooter
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        goToPage={gotoPage}
      />
    </div>
  );
};
