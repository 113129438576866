import {
  AUTHENTICATION_FAILURE_COUNT,
  AUTHORIZATION_FAILURE_COUNT,
  USER_INFO
} from "../Constants";
import {
  INCREMENT_AUTHENTICATION_FAILURE,
  INCREMENT_AUTHORIZATION_FAILURE,
  LOG_IN,
  LOG_OUT,
  UPDATE_USER_ROLES,
  UPDATE_TOKEN_EXPIRY_TIME
} from "./userActions";
import { RootAction } from "../rootReducer";
import { LoginInfo } from "../Dto/Security/UserInfo";

export const userReducer = (state: LoginInfo = {}, action: RootAction) => {
  switch (action.type) {
    case LOG_IN:
      const currentUserInfo = localStorage.getItem("user_info");
      const parsedUserInfo = currentUserInfo && JSON.parse(currentUserInfo);
      const newUserInfo = parsedUserInfo?.selectedRoles
        ? {
            ...action.payload.userInfo,
            selectedRoles: parsedUserInfo?.selectedRoles
          }
        : action.payload.userInfo;

      localStorage.setItem(USER_INFO, JSON.stringify(newUserInfo));
      localStorage.removeItem(AUTHENTICATION_FAILURE_COUNT);
      localStorage.removeItem(AUTHORIZATION_FAILURE_COUNT);
      return {
        ...state,
        userInfo: newUserInfo
      };
    case UPDATE_USER_ROLES:
      const userInfo = {
        ...state.userInfo,
        roles: action.payload,
        selectedRoles: action.payload
      };
      localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
      return {
        ...state,
        userInfo
      };
    case UPDATE_TOKEN_EXPIRY_TIME:
      let updatedUserInfo = {
        ...state.userInfo,
        tokenExpiresAt: action.payload
      };
      localStorage.setItem(USER_INFO, JSON.stringify(updatedUserInfo));
      return {
        ...state,
        userInfo: updatedUserInfo
      };
    case INCREMENT_AUTHENTICATION_FAILURE: {
      let count = Number(localStorage.getItem(AUTHENTICATION_FAILURE_COUNT));
      localStorage.setItem(
        AUTHENTICATION_FAILURE_COUNT,
        JSON.stringify(count + 1)
      );
      return state;
    }
    case INCREMENT_AUTHORIZATION_FAILURE: {
      let count = Number(localStorage.getItem(AUTHORIZATION_FAILURE_COUNT));
      localStorage.setItem(
        AUTHORIZATION_FAILURE_COUNT,
        JSON.stringify(count + 1)
      );
      return state;
    }
    case LOG_OUT:
      localStorage.removeItem(USER_INFO);
      localStorage.removeItem(AUTHENTICATION_FAILURE_COUNT);
      localStorage.removeItem(AUTHORIZATION_FAILURE_COUNT);
      return {};
    default:
      return state;
  }
};
