import { ReactTableColumn } from "../../../../../Component/Table/ReactTable";
import { ActivityLicense, ActivityLicenseStatusEnum } from "../../../../../../api_client/medre_api";
import { FormattedMessage } from "react-intl";
import { ActivityLicenseStatusFM } from "../../../../../Messages/ActivityLicenseFM";
import { GeneralFM } from "../../../../../Messages/GeneralFM";
import { formatDate } from "../../../Application/OverView/ApplicationOverViewUtil";
import { DetailViewLinkButton } from "../../../../../Component/Button/LinkButton";
import {
  InsuranceContractState,
  PublicInsuranceContractState
} from "../../../InsuranceContract/InsuranceContractState";
import React from "react";

// Delete file once 'vastutuskindlustus' in live

type ActivityLicenseColumnKey =
  | "number"
  | "company"
  | "businessArea"
  | "status"
  | "validUntil"
  | "detailsLink";
type ActivityLicenseColumnsObj = Record<ActivityLicenseColumnKey, ReactTableColumn<ActivityLicense>>;

const activityLicenseColumnsObj: ActivityLicenseColumnsObj = {
  number: {
    accessor: "number",
    Header: (
      <FormattedMessage
        id="filters.activityLicenseNumber"
        defaultMessage="Number"
      />
    ),
    width: 100
  },
  company: {
    id: "company.name",
    accessor: "company",
    Header: (
      <FormattedMessage
        id="filters.companyLabel"
        defaultMessage="Teenuse osutaja"
      />
    ),
    Cell: (cell) => cell.value?.name,
    width: 0
  },
  businessArea: {
    id: "businessArea.name",
    accessor: "businessArea",
    Header: (
      <FormattedMessage id="filters.businessArea" defaultMessage="Tegevusala" />
    ),
    Cell: (cell) => cell.value?.name,
    width: 0
  },
  status: {
    accessor: "status",
    Header: <FormattedMessage id="filters.validity" defaultMessage="Staatus" />,
    Cell: (cell) => (
      <ActivityLicenseStatusFM id={cell.value as ActivityLicenseStatusEnum} />
    ),
    width: 0
  },
  validUntil: {
    accessor: "validUntil",
    Header: <GeneralFM id="validUntil" />,
    Cell: (cell) =>
      cell.value ? formatDate(cell.value) : <GeneralFM id="termless" />,
    width: 0
  },
  detailsLink: {
    Header: "",
    accessor: "id",
    sticky: true,
    disableSortBy: true,
    Cell: ({ value: id }) => (
      <DetailViewLinkButton to={`/activity-licenses/${id}`} />
    ),
    width: 0
  }
};

const activityLicenseResultsColumns: ReactTableColumn<ActivityLicense>[] = [
  activityLicenseColumnsObj.number,
  activityLicenseColumnsObj.company,
  activityLicenseColumnsObj.businessArea,
  activityLicenseColumnsObj.status,
  activityLicenseColumnsObj.validUntil
];

export const temporaryActivityLicensePortalMobileColumns: ReactTableColumn<ActivityLicense>[] =
  [
    {
      ...activityLicenseColumnsObj.number,
      accessor: "number",
      Cell: ({ value }) => <span className="font-weight-500">{value}</span>
    },
    {
      ...activityLicenseColumnsObj.company,
      accessor: "company",
      Cell: ({ value }) => (
        <span className="font-weight-500">{value?.name}</span>
      )
    },
    activityLicenseColumnsObj.businessArea,
    activityLicenseColumnsObj.status,
    activityLicenseColumnsObj.validUntil,
    {
      id: "company.insuranceContractStatus",
      accessor: "company",
      Header: <FormattedMessage id="fiters.companyInsuranceContract" defaultMessage="Kindlustus" />,
      Cell: ({ value }) => (
        <PublicInsuranceContractState currentStatus={value?.insuranceContractStatus} />
      )
    },
    {
      ...activityLicenseColumnsObj.detailsLink,
      accessor: "id",
      Cell: ({ value: id }) => (
        <DetailViewLinkButton
          id="lookCloserLink"
          to={`/activity-licenses/${id}`}
        />
      )
    }
  ];

export const temporaryActivityLicensePortalColumns: ReactTableColumn<ActivityLicense>[] = [
  ...activityLicenseResultsColumns,
  {
    id: "company.insuranceContractStatus",
    accessor: "company",
    Header: <FormattedMessage id="fiters.companyInsuranceContract" defaultMessage="Kindlustus" />,
    Cell: ({ value }) => (
      <PublicInsuranceContractState currentStatus={value?.insuranceContractStatus} />
    )
  },
  {
    ...activityLicenseColumnsObj.detailsLink,
    accessor: "id",
    Cell: ({ value: id }) => (
      <DetailViewLinkButton
        id="lookCloserLink"
        to={`/activity-licenses/${id}`}
      />
    )
  }
];

export const temporaryActivityLicenseOfficialColumns: ReactTableColumn<ActivityLicense>[] = [
  {
    accessor: "createdAt",
    Header: <GeneralFM id="date" />,
    Cell: (cell) => formatDate(cell.value),
    width: 100
  },
  ...activityLicenseResultsColumns,
  {
    id: "company.insuranceContractStatus",
    accessor: "company",
    Header: <FormattedMessage id="fiters.companyInsuranceContract" defaultMessage="Kindlustus" />,
    Cell: ({ value }) => (
      <InsuranceContractState currentStatus={value?.insuranceContractStatus} />
    )
  },
  activityLicenseColumnsObj.detailsLink
];
