import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { ApplicationDetailsInfoCard } from "../../../../Component/Card/ApplicationDetailsInfoCard";
import {
  useGeneralPractitionerListSelector,
  useGeneralPractitionerListContacts
} from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { formatDate } from "../../Application/OverView/ApplicationOverViewUtil";
import DetailsSection from "../../../../Component/DetailsSection/DetailsSection";
import { GENERAL_PRACTITIONERS_DETAILS_SECTIONS } from "../GeneralPractitionersDetailedNavigationList";
import GeneralPractitionerListStatus from "../../../Official/GeneralPractitioners/GeneralPractitionerListStatus";
import GeneralPractitionerListServiceArea from "../GeneralPractitionerListServiceArea";
import { GeneralPractitionerListDoctor } from "../GeneralPractitionerListDoctor";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import PortalGPContactsModal from "../../../Portal/GeneralPractitioners/Modals/Contacts";
import { useAuthorized, usePortal } from "../../../../Hook/usePortal";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { joinJSX, uniqBy } from "../../../../Util/ArrayUtil";
import { LinkButton } from "../../../../Component/Button/LinkButton";
import { RootState } from "../../../../rootReducer";
import { GPListContactCard } from "./GPListContactCard";
import {
  GeneralPractitionerListStatusEnum,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI
} from "../../../../../api_client/medre_api";
import { LinkToPublicPortal } from "../LinkToPublicPortal";
import { Loader } from "../../../../Component/Loader/Loader";
import { API, getBaseUrl } from "../../../../api";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { alertActions } from "../../../../Alert/alertActions";
import { mapGPList } from "../useGeneralPractitionerList";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import { ProtectedComponent } from "../../../../Security/ProtectedComponent";

export const GPListContacts = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const list = useGeneralPractitionerListSelector();
  const contacts = useGeneralPractitionerListContacts();
  const isPortal = usePortal();
  const isAuthorized = useAuthorized();
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const handleOpenContactsModal = useCallback(() => {
    setOpenContactsModal(true);
  }, [setOpenContactsModal]);
  const handleCloseContactsModal = useCallback(() => {
    setOpenContactsModal(false);
  }, [setOpenContactsModal]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const renderContacts = () => (
    <>
      <h3 className="mt-4">
        <FormattedMessage
          id="contactCard.title"
          defaultMessage="Kontaktandmed"
        />
      </h3>
      {contacts.map((contact) => (
        <GPListContactCard key={contact.locationId} contact={contact} />
      ))}
    </>
  );  

  const handleSizeUpdate = async (): Promise<void> => {
    let alert = {
      id: 0,
      type: AlertType.Success,
      message: <AlertMessage id="gpContactSizeUpdateSuccess" />
    };

    try {
      setLoading(true);
      const response = mapGPList(
        await officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).refreshActualSizeInGeneralPractitionerList(list.id, {
          withCredentials: true
        })
      );
      dispatch(generalPractitionerListActions.setList(response));
    } catch (err) {
      alert = {
        id: 0,
        type: AlertType.Danger,
        message: <AlertMessage id="gpContactSizeUpdateFailed" />
      };
    } finally {
      dispatch(alertActions.addAlert(alert));
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader absolute backdrop />}
      <DetailsSection {...GENERAL_PRACTITIONERS_DETAILS_SECTIONS.DETAILS}>
        <PortalGPContactsModal
          open={isOpenContactsModal}
          onClose={handleCloseContactsModal}
        />

        {isPortal && isAuthorized && (
          <div className="d-block d-sm-none">
            <PrimaryFormattedButton
              id="updateContactInformation"
              idPrefix="xs"
              onClick={handleOpenContactsModal}
            />
          </div>
        )}
        <ApplicationDetailsInfoCard
          title={
            <>
              <FormattedMessage
                id="GPListContacts.detailsTitle"
                defaultMessage="Detailandmed"
              />
              {isPortal && isAuthorized && (
                <>
                  <PrimaryFormattedButton
                    className="d-none d-sm-inline-flex"
                    id="updateContactInformation"
                    idPrefix="sm"
                    onClick={handleOpenContactsModal}
                  />
                </>
              )}
            </>
          }
          content={[
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.number"
                  defaultMessage="Nimistu nr"
                />
              ),
              value: list?.code
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.doctor"
                  defaultMessage="Perearst"
                />
              ),
              value: (
                <LinkToPublicPortal
                  to={`/employees/${list?.generalPractitioner?.id}`}
                >
                  <GeneralPractitionerListDoctor
                    data={list.generalPractitioner}
                  />
                </LinkToPublicPortal>
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.substitute"
                  defaultMessage="Asendaja"
                />
              ),
              value: (
                <LinkToPublicPortal to={`/employees/${list?.substitute?.id}`}>
                  <GeneralPractitionerListDoctor data={list.substitute} />
                </LinkToPublicPortal>
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.substitutePeriod"
                  defaultMessage="Asenduse periood"
                />
              ),
              value: list?.substitute?.id && (
                <>
                  {formatDate(list.substitute.activationDate)}&nbsp;-&nbsp;
                  {formatDate(list.substitute.deactivationDate)}
                </>
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.serviceArea"
                  defaultMessage="Teeninduspiirkond"
                />
              ),
              value: (
                <GeneralPractitionerListServiceArea
                  serviceArea={list?.serviceArea}
                />
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.licenseCompanies"
                  defaultMessage="Teenuse osutaja"
                />
              ),
              value: uniqBy(list.licenses, "companyRegistryCode")
                .map(({ companyName }) => companyName)
                .join(" ,")
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.licenses"
                  defaultMessage="Tegevusluba"
                />
              ),
              value: (
                <div className="text-break">
                  {joinJSX<[string, JSX.Element][]>(
                    list.licenses.map(
                      ({
                        number: licenseNumber,
                        licenseId,
                        licenseStartDate,
                        licenseValidUntil
                      }: Record<string, any>) => [
                        licenseNumber,
                        <div key={licenseNumber}>
                          <LinkToPublicPortal
                            to={licenseId && `/activity-licenses/${licenseId}`}
                          >
                            {licenseNumber}
                          </LinkToPublicPortal>
                          &nbsp;
                          {licenseId && (
                            <>
                              (
                              {licenseValidUntil ? (
                                formatDate(licenseValidUntil)
                              ) : (
                                <span className="text-lowercase">
                                  <GeneralFM id="termless" />
                                </span>
                              )}
                              ,&nbsp;
                              <GeneralFM id="validFrom" />
                              &nbsp;
                              {formatDate(licenseStartDate)})
                              {!isPortal && (
                                <>
                                  &nbsp;
                                  <LinkButton
                                    id="lookAtDetails"
                                    className="p-0 text-decoration-none"
                                    tag={Link}
                                    to={`/activity-licenses/${licenseId}`}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      ]
                    ),
                    null
                  )}
                </div>
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.maxPatientsSize"
                  defaultMessage="Piirsuurus"
                />
              ),
              value: list?.maxPatientsSize
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.actualPatientsSize"
                  defaultMessage="Suurus {date}"
                  values={{
                    date:
                      list?.actualPatientsSizeDate &&
                      `(${formatDate(list?.actualPatientsSizeDate)})`
                  }}
                />
              ),
              value: (
                <>
                  <span className="mr-1">{list?.actualPatientsSize}</span>
                  {list?.status ===
                    GeneralPractitionerListStatusEnum.Register && (
                    <ProtectedComponent officialsOnly>
                      <LinkButton
                        id="updateSize"
                        className="p-0"
                        onClick={handleSizeUpdate}
                      />
                    </ProtectedComponent>
                  )}
                </>
              )
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.insuredPersonsSize"
                  defaultMessage="Kindlustatud isikuid"
                />
              ),
              value: list.insuredPersonsSize
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.uninsuredPersonsSize"
                  defaultMessage="Kindlustamata isikuid"
                />
              ),
              value: list.uninsuredPersonsSize
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.vacanciesSize"
                  defaultMessage="Vabade kohtade arv"
                />
              ),
              value: list.vacanciesSize
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.activationDate"
                  defaultMessage="Nimistu loomise kuupäev"
                />
              ),
              value: formatDate(list?.activationDate)
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.liquidationDate"
                  defaultMessage="Nimistu likvideerimise kuupäev"
                />
              ),
              value: formatDate(list?.liquidationDate),
              hidden:
                list?.status !== GeneralPractitionerListStatusEnum.Archives
            },
            {
              key: (
                <FormattedMessage
                  id="GPListContacts.status"
                  defaultMessage="Staatus"
                />
              ),
              value: <GeneralPractitionerListStatus status={list?.status} />,
              hidden: !userInfo
            }
          ]}
        />

        {renderContacts()}
      </DetailsSection>
    </>
  );
};
