import React from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { FormattedMessage } from "react-intl";
import { SecondaryFormattedButton } from "../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../Component/Button/PrimaryFormattedButton";
import { AdminTeenusApiFactory as adminTeenusApi, FeatureFlagRequestIdEnum } from "../../../../api_client/medre_api";
import { API, getBaseUrl } from "../../../api";
import { displayAlert } from "../../../Util/AlertUtil";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { featureFlagActions } from "../../../Security/featureFlagActions";
import { filterActions } from "../../Shared/Search/Filters/filterActions";
import { ActiveFilters } from "../../Shared/Search/Filters/FilterTypes";
import { searchResultsActions } from "../../Shared/Search/searchResultsActions";

export const FeatureFlagsView = () => {
  const dispatch = useDispatch();
  const featureFlags = useSelector((state: RootState) => state.featureFlag.featureFlags);

  // Eemaldada kui vastutuskindlustus live'i saab
  const handleFilterTabs = (flagId: FeatureFlagRequestIdEnum, value: boolean): void => {
    if (!value && flagId === FeatureFlagRequestIdEnum.InsuranceV1) {
      dispatch(searchResultsActions.setData([], ActiveFilters.Applicant));
      dispatch(searchResultsActions.setRowCount(0));
      dispatch(searchResultsActions.setPageCount(0));
      dispatch(searchResultsActions.setFilters(null));
      dispatch(filterActions.setFilterType(ActiveFilters.Applicant));
    }
  };

  const handleFeatureFlag = (flagId: FeatureFlagRequestIdEnum, value: boolean): void => {
    adminTeenusApi(undefined, getBaseUrl(), API)
      .updateFeatureFlag({ id: flagId, isEnabled: value }, { withCredentials: true })
      .then((r) => {
        if (r.data.isEnabled !== undefined) {
          dispatch(featureFlagActions.setFeatureFlag(flagId, value));
        }

        handleFilterTabs(flagId, value);
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch));
  };

  const renderFeatureFlagsActions = () => {
    return Object.entries(featureFlags).sort().map(([flagId, isEnabled]) => {
      if (flagId === FeatureFlagRequestIdEnum.TortaCheckEnabled) {
        return null;
      }

      return (
        isEnabled !== null &&
        <div className="mb-3" key={flagId}>
          <i className="mr-5">{ flagId }</i>
          <b className="mr-5">{ isEnabled.toString().toUpperCase() }</b>
          { isEnabled ?
            <SecondaryFormattedButton id="toggleOff" onClick={(): void => {
              handleFeatureFlag(flagId as FeatureFlagRequestIdEnum, !isEnabled);
            }}/> :
            <PrimaryFormattedButton id="toggleOn" onClick={(): void => {
              handleFeatureFlag(flagId as FeatureFlagRequestIdEnum, !isEnabled);
            }}/>
          }
        </div>
      );
    });
  };

  return (
    <>
      <Card className="system-administration-card">
        <div>
          <h3 className="system-administration-card-header fw-normal">
            <FormattedMessage
              id="systemAdministration.tab.featureFlags.header"
              defaultMessage="Funktsioonilippude sisse- ja väljalülitamine"
            />
          </h3>
        </div>
        <div>
          { renderFeatureFlagsActions() }
        </div>
      </Card>
    </>
  );
};