import {
  GeneralPractitioner,
  ServiceArea,
  AllowedServiceArea,
  GeneralPractitionerListLicense,
  GeneralPractitionerList,
  GeneralPractitionerListEmployee,
  GeneralPractitionerListSubstituteDoctor,
  GeneralPractitionerListAppointment
} from "../../../api_client/medre_api";

export interface GeneralPractitionerListState {
  list?: GeneralPractitionerList;
  licenses?: GeneralPractitionerListLicense[];
  serviceArea?: ServiceArea;
  allowedServiceAreas?: AllowedServiceArea[];
  generalPractitioner?: GeneralPractitioner;
  deletions: GPListBulkDeletionState;
}

export interface GeneralPractitionerListDto extends GeneralPractitionerList {
  employeesMap?: Map<string, GeneralPractitionerListEmployee>;
  substituteDoctorsMap?: Map<string, GeneralPractitionerListSubstituteDoctor>;
  locationContacts?: Record<string, string>;
  appointmentsGroup: Record<string, GeneralPractitionerListAppointment[]>;
}

export enum ModalKeys {
  assignSubstitute = "assignSubstitute",
  editSubstitute = "editSubstitute",
  createServiceArea = "createServiceArea",
  changeServiceArea = "changeServiceArea",
  addFamilyDoctor = "addFamilyDoctor",
  removeFamilyDoctor = "removeFamilyDoctor",
  assignLicense = "assignLicense",
  changeLicense = "changeLicense",
  changeMaxPatientsSize = "changeMaxPatientsSize",
  addActivationDate = "addActivationDate"
  // amendmentActivityLicense = "amendmentActivityLicense",
  // resizeThreshold = "resizeThreshold",
}

export interface GPListBulkDeletionState {
  employees: string[];
  appointments: string[];
  consultations: string[];
  substituteDoctors: string[];
  substituteDoctorPeriods: string[];
  [key: string]: Array<string>;
}
