import React, {
  FC,
  useCallback,
  Fragment,
  useMemo,
  useEffect,
  useState
} from "react";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { PrimaryFormattedButton } from "../../../../Component/Button/PrimaryFormattedButton";
import { getBaseUrl, API } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { CustomSelect } from "../../../../Component/Select/CustomSelect";
import { EmployeeFM, EmployeeRoleFM } from "../../../../Messages/EmployeeFM";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import {
  OccupationCode,
  GeneralPractitionerListEmployee,
  GeneralPractitionerListEmployeeRoleEnum,
  PerearstiNimistudTeenusApiFactory as doctorDirectoryServiceAPI
} from "../../../../../api_client/medre_api";
import { getFullName } from "../../../../Util/PersonUtils";

interface Props {
  open: boolean;
  onClose: () => void;
  employee: null | GeneralPractitionerListEmployee;
}

export interface EmployeeOption extends Option {
  id: string;
  idCode: string;
  occupationCodes: OccupationCode[];
  firstName: string;
  lastName: string;
}

interface FormValues {
  employee: EmployeeOption;
  role: Option;
}

const mapPrefixToRoles = {
  D: [
    GeneralPractitionerListEmployeeRoleEnum.Doctor,
    GeneralPractitionerListEmployeeRoleEnum.GeneralPractitioner,
    GeneralPractitionerListEmployeeRoleEnum.SubstituteDoctor
  ],
  N: [GeneralPractitionerListEmployeeRoleEnum.FamilyNurse]
};
type AllowedPrefixToRoles = keyof typeof mapPrefixToRoles;

const PortalEmployeeModal: FC<Props> = ({ open, onClose, employee }) => {
  const allowedPrefixToRoles = Object.keys(
    mapPrefixToRoles
  ) as AllowedPrefixToRoles[];

  const {
    list: { id },
    fetchGPList
  } = useGeneralPractitionerList();
  const [employeeOptions, setEmployeeOptions] = useState<Option[]>([]);

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    mode: "onChange",
    ...(employee && {
      defaultValues: {
        employee: {
          ...employee,
          occupationCodes: [employee.occupationCode]
        },
        role: {
          value: employee.role,
          label: <EmployeeRoleFM role={employee.role} />
        }
      }
    })
  });

  const { isSubmitting, isValid } = formState;
  useEffect(() => {
    if (open) {
      doctorDirectoryServiceAPI(undefined, getBaseUrl(), API)
        .getAllowedEmployees(id, {
          withCredentials: true
        })
        .then((res) => {
          setEmployeeOptions(
            res.data.map((selectedEmployee) => ({
              ...selectedEmployee,
              value: selectedEmployee.id as string,
              label: (
                <div className="d-flex flex-row">
                  <div className="d-flex flex-row">
                    {selectedEmployee.occupationCodes?.map(({ code }) => (
                      <Fragment key={code}>
                        <strong>{code}</strong>&nbsp;
                      </Fragment>
                    ))}
                  </div>
                  {getFullName(selectedEmployee)}
                </div>
              )
            }))
          );
        });
    }
  }, [open, id]);

  const { employee: employeeValue } = watch();

  const roleOptions = useMemo(
    () => ({
      [GeneralPractitionerListEmployeeRoleEnum.GeneralPractitioner]: {
        value: GeneralPractitionerListEmployeeRoleEnum.GeneralPractitioner,
        label: (
          <EmployeeRoleFM
            role={GeneralPractitionerListEmployeeRoleEnum.GeneralPractitioner}
          />
        )
      },
      [GeneralPractitionerListEmployeeRoleEnum.SubstituteDoctor]: {
        value: GeneralPractitionerListEmployeeRoleEnum.SubstituteDoctor,
        label: (
          <EmployeeRoleFM
            role={GeneralPractitionerListEmployeeRoleEnum.SubstituteDoctor}
          />
        )
      },
      [GeneralPractitionerListEmployeeRoleEnum.FamilyNurse]: {
        value: GeneralPractitionerListEmployeeRoleEnum.FamilyNurse,
        label: (
          <EmployeeRoleFM
            role={GeneralPractitionerListEmployeeRoleEnum.FamilyNurse}
          />
        )
      },
      [GeneralPractitionerListEmployeeRoleEnum.Doctor]: {
        value: GeneralPractitionerListEmployeeRoleEnum.Doctor,
        label: (
          <EmployeeRoleFM
            role={GeneralPractitionerListEmployeeRoleEnum.Doctor}
          />
        )
      }
    }),
    []
  );

  const getRoleOptions = useMemo(() => {
    if (!employeeValue) {
      return [];
    }

    const employeePrefixes = employeeValue.occupationCodes
      .map(({ prefix }) => prefix)
      .filter((prefix) =>
        allowedPrefixToRoles.includes(prefix as AllowedPrefixToRoles)
      ) as AllowedPrefixToRoles[];

    const employeeRoles = employeePrefixes.reduce<
      GeneralPractitionerListEmployeeRoleEnum[]
    >((acc, prefix) => [...acc, ...mapPrefixToRoles[prefix]], []);
    return employeeRoles.map((role) => roleOptions[role]);
  }, [employeeValue, roleOptions, allowedPrefixToRoles]);

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (employee) {
          await doctorDirectoryServiceAPI(
            undefined,
            getBaseUrl(),
            API
          ).updateEmployee(
            id,
            {
              personId: employee.personId,
              role: values.role.value
            } as GeneralPractitionerListEmployee,
            {
              withCredentials: true
            }
          );
        } else {
          await doctorDirectoryServiceAPI(
            undefined,
            getBaseUrl(),
            API
          ).addEmployee(
            id,
            {
              personId: values.employee.value,
              role: values.role.value
            } as GeneralPractitionerListEmployee,
            {
              withCredentials: true
            }
          );
        }
      } catch (e) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }

      await fetchGPList(id);
      onClose();
    },
    [id, fetchGPList, onClose, dispatch, employee]
  );

  return (
    <Modal isOpen={open} size="md" unmountOnClose={true}>
      <ModalHeader>
        {!employee ? (
          <FormattedMessage
            id="GPListEmployeeModal.header"
            defaultMessage="Lisa nimistusse töötaja"
          />
        ) : (
          <FormattedMessage
            id="GPListEmployeeModal.title"
            defaultMessage="Muuda {fullName} andmeid "
            values={{ fullName: getFullName(employee) }}
          />
        )}
      </ModalHeader>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gp-contact-information-form"
      >
        <ModalBody>
          <Controller
            name="employee"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({
              value,
              onChange
            }: ControllerRenderProps<Record<string, any>>) =>
              !employee ? (
                <FormGroup row={true}>
                  <Label
                    htmlFor="gpListEmployeeModalEmployeeId"
                    className="text-right"
                    sm="3"
                  >
                    <EmployeeFM id="addEmployee" />
                  </Label>
                  <Col sm={9}>
                    <SingleSelect
                      inputId="gpListEmployeeModalEmployeeId"
                      options={employeeOptions}
                      value={value}
                      handleOptionChange={onChange}
                      filterOptions={(option, text) => {
                        const optionValue =
                          option.data.occupationCodes
                            ?.map((code: OccupationCode) => code.code)
                            .join(" ") +
                          " " +
                          getFullName({ ...option.data });
                        return optionValue
                          .toLocaleLowerCase()
                          .includes(text.toLocaleLowerCase());
                      }}
                      placeholder={
                        <FormattedMessage
                          id="asyncCustomSelect.search"
                          defaultMessage="Otsi"
                        />
                      }
                    />
                  </Col>
                </FormGroup>
              ) : (
                <input type="hidden" />
              )
            }
          />

          <FormGroup row={true}>
            <Label
              htmlFor="gpListEmployeeModalEmployeeRole"
              className="text-right"
              sm="3"
            >
              <EmployeeFM id="setRoll" />
            </Label>
            <Col sm={9}>
              <Controller
                name="role"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <CustomSelect
                    inputId="gpListEmployeeModalEmployeeRole"
                    disabled={!employee && !employeeValue}
                    value={value}
                    handleOptionChange={onChange}
                    options={getRoleOptions}
                    hideSearchIcon={true}
                  />
                )}
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            type="button"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <PrimaryFormattedButton
            id="addToEmployeeList"
            type="submit"
            disabled={!isValid || isSubmitting}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default PortalEmployeeModal;
