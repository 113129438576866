import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { ProtectedFieldWrapper } from "../../../Security/ProtectedFieldWrapper";
import { ModalKeys } from "../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import Substitute from "./Modals/Substitute";
import ServiceArea from "./Modals/ServiceArea";
import License from "./Modals/License";
import AddActivationDate from "./Modals/AddActivationDate";
import ChangeMaxPatientsSize from "./Modals/ChangeMaxPatientsSize";
import {
  useGeneralPractitionerListGeneralPractitionerSelector,
  useGeneralPractitionerListSelector,
  useGeneralPractitionerListServiceAreaSelector
} from "../../../GeneralPractitionerLists/generalPractitionerListSelector";
import DetailsSection from "../../../Component/DetailsSection/DetailsSection";
import FamilyDoctor from "./Modals/FamilyDoctor";
import RemoveFamilyDoctor from "./Modals/RemoveFamilyDoctor";
import {
  GeneralPractitionerListStatusEnum,
  OfficialUserPrivilegesEnum
} from "../../../../api_client/medre_api";
import "./GeneralPractitionersDetailedActions.scss";

interface Props {}

const MODALS: { [key in ModalKeys]: Function } = {
  [ModalKeys.assignSubstitute]: Substitute,
  [ModalKeys.editSubstitute]: Substitute,
  [ModalKeys.createServiceArea]: ServiceArea,
  [ModalKeys.changeServiceArea]: ServiceArea,
  [ModalKeys.assignLicense]: License,
  [ModalKeys.changeLicense]: License,
  [ModalKeys.changeMaxPatientsSize]: ChangeMaxPatientsSize,
  [ModalKeys.addActivationDate]: AddActivationDate,
  [ModalKeys.addFamilyDoctor]: FamilyDoctor,
  [ModalKeys.removeFamilyDoctor]: RemoveFamilyDoctor
};

type ActionButton = {
  id: ModalKeys;
  label: string;
  hidden?: boolean;
};

export const GeneralPractitionersDetailedActions: React.FC<Props> = () => {
  const [activeModalKey, setActiveModalKey] = useState<ModalKeys>();
  const list = useGeneralPractitionerListSelector();
  const generalPractitioner =
    useGeneralPractitionerListGeneralPractitionerSelector();
  const serviceArea = useGeneralPractitionerListServiceAreaSelector();
  const CurrentModal = MODALS[activeModalKey!] ?? null;

  const buttons: ActionButton[] = useMemo(
    () => [
      {
        id: ModalKeys.assignSubstitute,
        label: "Määra asendaja",
        hidden: [
          list.status !== GeneralPractitionerListStatusEnum.Register,
          Boolean(list?.substitute?.id),
          !generalPractitioner.deactivationDate &&
            Boolean(list?.generalPractitioner?.id || list?.substitute?.id)
        ].includes(true)
      },
      {
        id: ModalKeys.editSubstitute,
        label: "Muuda asendaja",
        hidden: Boolean(list?.generalPractitioner?.id || !list?.substitute?.id)
      },
      {
        id: ModalKeys.createServiceArea,
        label: "Määra teeninduspiirkond",
        hidden: Boolean((serviceArea?.ids).length)
      },
      {
        id: ModalKeys.changeServiceArea,
        label: "Muuda teeninduspiirkond",
        hidden: !Boolean((serviceArea?.ids).length)
      },
      {
        id: ModalKeys.addFamilyDoctor,
        label: "Määra perearst",
        hidden:
          Boolean(list?.generalPractitioner?.id) &&
          !generalPractitioner.deactivationDate
      },
      {
        id: ModalKeys.removeFamilyDoctor,
        label: generalPractitioner.deactivationDate
          ? "Muuda perearsti eemaldamist"
          : "Eemalda perearst",
        hidden: !Boolean(list?.generalPractitioner?.id)
      },
      {
        id: ModalKeys.assignLicense,
        label: "Määra tegevusluba",
        hidden: Boolean(
          (!list.generalPractitioner?.id && !list.substitute?.id) ||
            list.licenses.length
        )
      },
      {
        id: ModalKeys.changeLicense,
        label: "Muuda tegevusluba",
        hidden: Boolean(!list.licenses.length)
      },
      {
        id: ModalKeys.changeMaxPatientsSize,
        label: list.maxPatientsSize
          ? "Piirsuuruse muutmine"
          : "Määra piirsuurus"
      },
      {
        id: ModalKeys.addActivationDate,
        label: "Nimistu kehtivuse kuupäev",
        hidden: Boolean(
          list.activationDate && new Date(list.activationDate) < new Date()
        )
      }
      // {
      //   id: "amendmentActivityLicense",
      //   label: "Tegevusloa muutmine",
      // },
      // {
      //   id: "resizeThreshold",
      //   label: "Piirsuuruse muutmine",
      // },
    ],
    [list, generalPractitioner, serviceArea]
  );
  const handleOpenModal = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const { id } = e.currentTarget.dataset;

      setActiveModalKey(id as ModalKeys);
    },
    [setActiveModalKey]
  );

  const handleCloseModal = useCallback(() => {
    setActiveModalKey(undefined);
  }, [setActiveModalKey]);

  if (list.status === GeneralPractitionerListStatusEnum.Archives) {
    return null;
  }

  return (
    <DetailsSection id="actions" title="Tegevused">
      <ProtectedFieldWrapper
        allowedRoles={[
          OfficialUserPrivilegesEnum.Proceede,
          OfficialUserPrivilegesEnum.Sign
        ]}
      >
        <div>
          {buttons.map((button) =>
            button.hidden ? null : (
              <Button
                key={button.id}
                type="button"
                outline={true}
                className="m-2 btn-secondary"
                onClick={handleOpenModal}
                data-id={button.id}
              >
                {button.label}
              </Button>
            )
          )}
        </div>
      </ProtectedFieldWrapper>

      {activeModalKey && CurrentModal && (
        <CurrentModal
          id={activeModalKey}
          onClose={handleCloseModal}
          onOpenModal={setActiveModalKey}
        />
      )}
    </DetailsSection>
  );
};
