import { Option } from "../../../../Component/Select/SingleSelect";
import {
  Decision,
  ActivityLicenseApplicationProceedingTypeEnum,
  OccupationCode,
  GeneralPractitionerListStatusEnum,
  ActivityLicenseApplicationStatus,
  DetailedApplicationTypeEnum,
  ProceedingStatus,
  Speciality,
  Representative,
  DecisionStatusEnum,
  PowersOfAttorneySearchFilter,
  ApplicationSearch, InsuranceContractStatus, DateOperator
} from "../../../../../api_client/medre_api";

export interface SpecialityListItem {
  option: Option;
  occupation: string;
}

export interface Country {
  code: string;
}

export interface CountryListItem {
  option: Option;
  group: string;
}

export enum CountryGroups {
  Estonia = "ESTONIA",
  Eca = "ECA",
  Third = "THIRD"
}

export enum RelativeTimes {
  Today = "Täna",
  Yesterday = "Eile",
  CurrentWeek = "Praegune nädal",
  CurrentMonth = "Praegune kuu",
  CurrentYear = "Praegune aasta",
  Manual = "Käsitsi"
}

export enum ActiveFilters {
  Applicant = "applicant",
  Application = "application",
  ActivityLicenseApplications = "activityLicenseApplications",
  ApplicationCertificate = "applicationCertificate",
  GeneralPractitionerList = "generalPractitionerList",
  Company = "company",
  ActivityLicenses = "activityLicense",
  PublicGeneralPractitionerList = "publicGeneralPractitionerList",
  PublicHealthcareEmployees = "publicHealthcareEmployees",
  PublicActivityLicenses = "publicActivityLicense",
  DecisionTHT = "decisionTHT",
  DecisionLicense = "decisionLicense",
  RepresentativeList = "representativeList",
  InsuranceContract = "insuranceContract"
}

export type PeriodOptions = {
  [time in RelativeTimes]: Option;
};

export interface ApplicantFilters {
  name: string;
  idCodeCountry: string;
  idCode: string;
  dateOfBirth?: string;
  sex: string | null;
  citizenship: string;
  status: string | null;
  occupationCode: string;
  occupationNames: string[];
  specialityCodes: string[];
  educationCountryGroup: string | null;
  educationCountries: string[];
}

export interface ApplicationFilters {
  occupationNames: string[];
  specialityCodes: string[];
  applicationNumber: number | null;
  currentStatuses: string[];
  educationCountryGroup: string | null;
  educationCountries: string[];
  stateFeePaid: boolean | null;
  submitDate: {
    start?: string;
    end?: string;
  };
  decisionNumber?: number;
  acceptedAutomatically?: boolean;
  decisionStatuses?: string[];
  onlyApplicationCertificate?: boolean;
}

export interface ActivityLicenseApplicationFilters {
  businessName: string;
  businessAreaIds: string[];
  applicationStatuses?: ActivityLicenseApplicationStatus[];
  proceedingOfficialId: string;
  applicationNumber?: number | null;
  hospitalTypeIds: string[];
  submitDate: {
    start?: string;
    end?: string;
  };
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
}

export interface ApplicationCertificateFilters {
  issueDate: {
    start?: string;
    end?: string;
  };
}

export interface CompanyFilters {
  companyName: string;
  registryCode: string;
  representativeName: string;
}

export interface GeneralPractitionerListFilters {
  code: string;
  activationDate?: string;
  gpOccupationCode?: string;
  substituteOccupationCode?: string;
  serviceAreaValue?: string;
  status?: GeneralPractitionerListStatusEnum;
}

export interface PublicGeneralPractitionerListFilters {
  gpSearchCode?: string;
  companyName?: string;
  serviceAreaValue?: string;
  onlyWithVacancies?: boolean;
  code?: string;
}

export interface PublicActivityLicenseFilters {
}

export interface PublicHealthcareEmployeesFilters {
  occupationCode?: string;
  firstName?: string;
  lastName?: string;
  occupation?: string;
  speciality?: string;
}

export interface DecisionFilters {
  decisionNumber?: string;
  decisionSearchStatuses?: DecisionStatusEnum[];
}

export interface DecisionTHTFilters extends DecisionFilters {
  personName?: string;
  countryCode?: string;
  personIdCode?: string;
  acceptedAutomatically?: boolean;
}

export const decisionTHTResetValues: DecisionTHTFilters = {};

export interface DecisionLicenseFilters extends DecisionFilters {
  companyName?: string;
}

export interface InsuranceContractFilters {
  policyHolder: string;
  insurer: string;
  number: string | null;
  status: string[];
  periodStartDate?: string;
  periodEndDate?: string;
  insuranceSumFrom: number | "";
  insuranceSumTo: number | "";
  periodStartDateOperator: DateOperator;
  periodEndDateOperator: DateOperator;
}

export type UnionFiltersType =
  | ApplicationFilters
  | ApplicantFilters
  | ActivityLicenseApplicationFilters
  | ApplicationCertificateFilters
  | CompanyFilters
  | GeneralPractitionerListFilters
  | PublicGeneralPractitionerListFilters
  | PublicHealthcareEmployeesFilters
  | DecisionTHTFilters
  | DecisionLicenseFilters
  | PowersOfAttorneySearchFilter
  | InsuranceContractFilters;

export enum QueryKeys {
  ApplicationNumber = "app_no",
  Statuses = "statuses",
  StateFee = "fee",
  Occupation = "occup",
  Speciality = "spec",
  Period = "period",
  StartDate = "start",
  EndDate = "end",
  CountryGroup = "group",
  Country = "country"
}

export interface QueryParams {
  [param: string]: string;
}

export interface SearchApplicant {
  lastName: string;
  firstName: string;
  status: string;
  idCodeCountry: string;
  idCode: string;
  id?: string;
  occupationCodes: OccupationCode[];
  specialities: Speciality[];
}

export interface SearchApplication extends ApplicationSearch {
  isDraft: boolean;
  applicationLink?: string;
}

export interface SearchActivityLicenseApplication {
  actualBusinessAreaId?: string;
  actualBusinessAreaName?: string;
  applicationNumber?: number;
  applicationStatus?: ActivityLicenseApplicationStatus;
  arrivalTime?: Date;
  businessArea?: string;
  businessName?: string;
  dateOfIssue?: Date;
  hospitalTypeId?: string;
  id?: string;
  proceedingPausedDays?: 0;
  proceedingType?: ActivityLicenseApplicationProceedingTypeEnum;
  stateFeeIsPaid?: boolean;
}

export interface SearchApplicationCertificate {
  firstName: string;
  lastName: string;
  occupationCodes: OccupationCode[];
  speciality: Speciality;
  countries: Country[];
  educationCountries: Country[];
  issueDate: Date;
}

export interface SearchCompany {
  id: string;
  name: string;
  registryCode: string;
  representatives: Representative[];
  activityLicenseNumbers: string[];
}

export interface SearchInsuranceContract {
  policyHolderName: string;
  insurerName: string;
  number: string;
  versionNumber: string;
  issueDate: Date;
  periodStartDate: Date;
  periodEndDate: Date;
  periodNewEndDate?: Date;
  insuranceSum: number;
  status: InsuranceContractStatus;
}

export interface SearchDecisionTHT extends Decision {
  personName: string;
  status: DecisionStatusEnum;
}

export interface SearchDecisionLicense extends Decision {
  companyName: string;
  businessAreaName: string;
  status: DecisionStatusEnum;
}
