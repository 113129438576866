import React, { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { FormattedMessage } from "react-intl";
import AsyncSelect from "react-select/async";
import { ActionMeta, ValueType } from "react-select";

import { filterSelectors } from "./filterStore";
import { filterActions, FilterItemData } from "./filterActions";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import {
  Option,
  SingleSelect
} from "../../../../Component/Select/SingleSelect";
import GeneralPractitionerListStatus from "../../../Official/GeneralPractitioners/GeneralPractitionerListStatus";
import fetchApplicantOptions from "../../GeneralPractitioners/fetchApplicantOptions";
import {
  FilterColumn,
  FilterContainer,
  FilterField
} from "../../../../Component/Filter/Filter";
import { GeneralFM } from "../../../../Messages/GeneralFM";
import { singleSelectProps } from "./FilterUtils";
import { GeneralPractitionerListStatusEnum } from "../../../../../api_client/medre_api";
import "./AsyncSelect.scss";

const STATUS_LIST = [
  {
    value: GeneralPractitionerListStatusEnum.Proceeding,
    label: (
      <GeneralPractitionerListStatus
        status={GeneralPractitionerListStatusEnum.Proceeding}
      />
    )
  },
  {
    value: GeneralPractitionerListStatusEnum.Register,
    label: (
      <GeneralPractitionerListStatus
        status={GeneralPractitionerListStatusEnum.Register}
      />
    )
  },
  {
    value: GeneralPractitionerListStatusEnum.Archives,
    label: (
      <GeneralPractitionerListStatus
        status={GeneralPractitionerListStatusEnum.Archives}
      />
    )
  }
];

export const GeneralPractitionerListFilters = () => {
  const dispatch = useDispatch();
  const state = useSelector(
    filterSelectors.selectGeneralPractitionerListFilters,
    shallowEqual
  );
  const set = useCallback(
    (key: string, data: FilterItemData) => {
      dispatch(filterActions.setGeneralPractitionerListFilter({ key, data }));
    },
    [dispatch]
  );
  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      set(name, value);
    },
    [set]
  );
  const handleChangeActivationDate = useCallback(
    (value) => {
      set("activationDate", value);
    },
    [set]
  );
  const handleChangeAsyncSelect = useCallback(
    (value: ValueType<Option, boolean>, { name }: ActionMeta<Option>) => {
      set(name!, value as Option);
    },
    [set]
  );
  const handleChangeSelect = useCallback(
    (value: Option, name: string) => {
      set(name, value);
    },
    [set]
  );

  const loadPersonOptions = useCallback(
    async (occupationCode: string) =>
      await fetchApplicantOptions({ occupationCode }),
    []
  );

  return (
    <FilterContainer>
      <FilterColumn xl={4}>
        <FilterField
          id="generalPractitionerListFiltersCode"
          label={
            <FormattedMessage
              id="filters.codeLabel"
              defaultMessage="Nimistu nr"
            />
          }
          field={
            <Input
              id="generalPractitionerListFiltersCode"
              className="w-100"
              value={state.code}
              onChange={handleChangeInput}
              placeholder="Nimistu number"
              name="code"
            />
          }
        />
        <FilterField
          id="generalPractitionerListFiltersActivationDate"
          label={
            <FormattedMessage
              id="filters.activationDateLabel"
              defaultMessage="Aktiveerimise kuupäev"
            />
          }
          field={
            <DatePickerComponent
              id="generalPractitionerListFiltersActivationDate"
              onDateChange={handleChangeActivationDate}
              selectedDate={state.activationDate}
              placeholder="pp.kk.aaaa"
            />
          }
        />
        <FilterField
          id="generalPractitionerListFiltersGeneralPractitioner"
          label={
            <FormattedMessage
              id="filters.generalPractitionerLabel"
              defaultMessage="Perearst"
            />
          }
          field={
            <AsyncSelect
              className="async-select"
              inputId="generalPractitionerListFiltersGeneralPractitioner"
              value={state.generalPractitioner}
              placeholder={
                <FormattedMessage
                  id="filters.occupationCodeLabel"
                  defaultMessage="THT kood"
                />
              }
              onChange={handleChangeAsyncSelect}
              loadOptions={loadPersonOptions}
              isClearable={true}
              name="generalPractitioner"
            />
          }
        />
      </FilterColumn>

      <FilterColumn xl={4}>
        <FilterField
          id="generalPractitionerListFiltersSubstitute"
          label={
            <FormattedMessage
              id="filters.substituteLabel"
              defaultMessage="Asendaja"
            />
          }
          field={
            <AsyncSelect
              className="async-select"
              inputId="generalPractitionerListFiltersSubstitute"
              value={state.substitute}
              placeholder={
                <FormattedMessage
                  id="filters.occupationCodeLabel"
                  defaultMessage="THT kood"
                />
              }
              onChange={handleChangeAsyncSelect}
              loadOptions={loadPersonOptions}
              isClearable={true}
              name="substitute"
            />
          }
        />
        <FilterField
          id="generalPractitionerListFiltersServiceArea"
          label={
            <FormattedMessage
              id="filters.serviceAreaLabel"
              defaultMessage="Teeninduspiirkond"
            />
          }
          field={
            <Input
              id="generalPractitionerListFiltersServiceArea"
              className="w-100"
              value={state.serviceArea}
              onChange={handleChangeInput}
              placeholder="Piirkond"
              name="serviceArea"
            />
          }
        />
        <FilterField
          id="generalPractitionerListFiltersStatus"
          label={
            <FormattedMessage
              id="filters.statusLabel"
              defaultMessage="Staatus"
            />
          }
          field={
            <SingleSelect
              inputId="generalPractitionerListFiltersStatus"
              options={STATUS_LIST}
              value={state.status}
              handleOptionChange={handleChangeSelect}
              name="status"
              placeholder={<GeneralFM id="selectPlaceholderAll" />}
              {...singleSelectProps}
            />
          }
        />
      </FilterColumn>
    </FilterContainer>
  );
};
