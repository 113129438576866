import React, { FC, ReactElement, useCallback, useEffect } from "react";
import { Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { AxiosPromise } from "axios";

import { ApplicantResults } from "../../Official/Search/ResultsTable/ApplicantResults";
import { ApplicationResults } from "../../Official/Search/ResultsTable/ApplicationResults";
import {
  ActivityLicenseApplicationResults
} from "../../Official/Search/ResultsTable/ActivityLicenseApplicationResults";
import { ApplicationCertificateResults } from "../../Official/Search/ResultsTable/ApplicationCertificateResults";
import { GeneralPractitionerListResults } from "./ResultsTable/GeneralPractitionerListResults";
import { CompanyResults } from "../../Official/Search/ResultsTable/CompanyResults";
import { InsuranceContractResults } from "../../Official/Search/ResultsTable/InsuranceContractResults";
import { ActivityLicenseResults } from "./ResultsTable/ActivityLicenseResults";
import { HealthcareEmployeesResults } from "../../Portal/Search/ResultsTable/HealthcareEmployeesResults";
import { DecisionTHTResults } from "../../Official/Search/ResultsTable/DecisionTHTResults";
import { DecisionLicenseResults } from "../../Official/Search/ResultsTable/DecisionLicenseResults";
import { Filters } from "./Filters/Filters";
import { API, getBaseUrl } from "../../../api";
import { ActiveFilters } from "./Filters/FilterTypes";
import { searchResultsActions } from "./searchResultsActions";
import { RootState } from "../../../rootReducer";
import { Loader } from "../../../Component/Loader/Loader";
import { filterSelectors } from "./Filters/filterStore";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import emptyResults from "../../../../assets/images/empty_results.svg";
import "./SearchApplication.scss";
import {
  ActivityLicenseProceedingSearchFilter,
  ActivityLicenseSearchFilter,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI,
  AmetnikuSertifikaadiAndmeteenusApiFactory as officialCertificateDataAPI,
  AmetnikuTaotluseAndmeteTeenusApiFactory as officialAppDataAPI,
  AmetnikuTegevuslubadeTaotlusteTeenusApiFactory as officialActivityPermitAppAPI,
  AmetnikuTegevuslubadeTeenusApiFactory as officialActivityPermitAPI,
  ApplicationSearchFilter,
  AvalikPerearstiNimistudTeenusApiFactory as publicGpDirectoryAPI,
  AvalikTegevuslubadeTeenusApiFactory as publicActivityPermitAPI,
  AvalikudIsikudLoetlevadTeenustApiFactory as publicEntityListAPI,
  CompanySearchFilter,
  DecisionSearchFilter,
  EttevteteAndmetegaSeotudTegevusedApiFactory as corporateDataActivityAPI,
  GeneralPractitionerListSearchFilter,
  HealthCareProfessionalPublicSearchFilter, InsuranceContractSearchFilter,
  KasutajaAndmeteTeenusAmetnikeleApiFactory as officialUserDataAPI,
  OtsusteTeenusApiFactory as decisionsServiceAPI,
  PersonCertificateSearchFilter,
  PersonSearchFilter,
  VastutuskindlustusteTeenusApiFactory as insuranceContractAPI
} from "../../../../api_client/medre_api";
import { displayAlert } from "../../../Util/AlertUtil";

interface Props {
  hideFilter?: boolean;
  hideFilterSelector?: boolean;
  hideResultsAtStart?: boolean;
  title?: ReactElement;
  buttons?:
  | (({ handleExport }: {handleExport: () => void}) => ReactElement)
  | ReactElement
  | null;
}

export const Search: FC<Props> = ({
  hideFilter = false,
  hideFilterSelector = false,
  hideResultsAtStart = false,
  title = "Otsing",
  buttons
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { pageSize, pageIndex, pageCount, rowCount, data, filters, sort } =
    useSelector((rootState: RootState) => rootState.searchResults);
  const isInsuranceEnabled = useSelector((state: RootState) => state.featureFlag.featureFlags.INSURANCE_V1);
  const applicantFilters = useSelector(
    filterSelectors.selectApplicantFilterValues
  );
  const applicationFilters = useSelector(
    filterSelectors.selectApplicationFilterValues
  );
  const activityLicenseApplicationFilters = useSelector(
    filterSelectors.selectActivityLicenseApplicationFilterValues
  );
  const applicationCertificateFilters = useSelector(
    filterSelectors.selectApplicationCertificateFilterValues
  );
  const companyFilters = useSelector(filterSelectors.selectCompanyFilterValues);
  const generalPractitionerListFilters = useSelector(
    filterSelectors.selectGeneralPractitionerListFilterValue
  );
  const publicGeneralPractitionerListFilters = useSelector(
    filterSelectors.selectPublicGeneralPractitionerListFilterValue
  );
  const activityLicenseFilters = useSelector(
    filterSelectors.selectActivityLicensesFilterValue
  );
  const officialActivityLicenseFilters = useSelector(
    filterSelectors.selectOfficialActivityLicensesFilterValue
  );
  const publicHealthcareEmployeesFilters = useSelector(
    filterSelectors.selectPublicHealthcareEmployeesFilterValue
  );
  const decisionTHTFilters = useSelector(
    filterSelectors.selectDecisionTHTFilterValue
  );
  const decisionLicenseFilters = useSelector(
    filterSelectors.selectDecisionLicenseFilterValue
  );
  const insuranceContractFilters = useSelector(filterSelectors.selectInsuranceContractFilterValues);
  const filterType = useSelector(filterSelectors.selectFilterType);

  const fetchData = () => {
    switch (filterType) {
      case ActiveFilters.Applicant:
        return officialUserDataAPI(undefined, getBaseUrl(), API).filterPersons1(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as PersonSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.Application:
        return officialAppDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).filterApplications(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as ApplicationSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.ActivityLicenseApplications:
        return officialActivityPermitAppAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchActivityLicenseProceedings(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as ActivityLicenseProceedingSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.ApplicationCertificate:
        return officialCertificateDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchCertificates(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as PersonCertificateSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.GeneralPractitionerList:
        return officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchGeneralPractitionerList1(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as GeneralPractitionerListSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.Company:
        return corporateDataActivityAPI(
          undefined,
          getBaseUrl(),
          API
        ).filterCompanies(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as CompanySearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.ActivityLicenses:
        // Remove once 'vastutuskindlustus' in live and change ...updatedFilters back to ...filters
        const updatedFilters = {
          ...filters,
          insuranceContractStatuses: isInsuranceEnabled ? filters!["insuranceContractStatuses"] : []
        };

        return officialActivityPermitAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchActivityLicenseList1(
          {
            ...updatedFilters,
            sort,
            page: pageIndex,
            size: pageSize
          } as ActivityLicenseSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.PublicGeneralPractitionerList:
        return publicGpDirectoryAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchGeneralPractitionerList(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as GeneralPractitionerListSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.PublicActivityLicenses:
        // Remove once 'vastutuskindlustus' in live and change ...updFilters back to ...filters
        const updFilters = {
          ...filters,
          insuranceContractStatuses: isInsuranceEnabled ? filters!["insuranceContractStatuses"] : []
        };
        
        return publicActivityPermitAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchActivityLicenseList(
          {
            ...updFilters,
            sort,
            page: pageIndex,
            size: pageSize
          } as ActivityLicenseSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.PublicHealthcareEmployees:
        return publicEntityListAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchHealthcareEmployee(
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as HealthCareProfessionalPublicSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.DecisionTHT:
        return decisionsServiceAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchDecisions(
          "THT",
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as DecisionSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.DecisionLicense:
        return decisionsServiceAPI(
          undefined,
          getBaseUrl(),
          API
        ).searchDecisions(
          "LICENSE",
          {
            ...filters,
            sort,
            page: pageIndex,
            size: pageSize
          } as DecisionSearchFilter,
          {
            withCredentials: true
          }
        );
      case ActiveFilters.InsuranceContract:
        return insuranceContractAPI(undefined, getBaseUrl(), API)
          .searchInsuranceContracts(
            {
              ...filters,
              sort,
              page: pageIndex,
              size: pageSize
            } as InsuranceContractSearchFilter,
            {
              withCredentials: true
            }
          );
    }
  };

  useEffect(() => {
    if (!filters || !filterType) {
      return;
    }

    const fetchResults = () => {
      setLoading(true);
      (fetchData() as AxiosPromise<any>)
        .then((res) => {
          dispatch(
            searchResultsActions.setData(
              res.data.content as Array<any>,
              filterType
            )
          );
          dispatch(searchResultsActions.setRowCount(res.data.totalElements!));
          dispatch(searchResultsActions.setPageCount(res.data.totalPages!));
        })
        .catch(() => {
          const alertMessage = <AlertMessage id="requestFailed"/>;
          const alert = {
            id: 0,
            type: AlertType.Danger,
            message: alertMessage
          };
          dispatch(alertActions.addAlert(alert));
          dispatch(searchResultsActions.setData([], filterType));
        })
        .finally(() => setLoading(false));
    };

    fetchResults();
  }, [dispatch, filters, sort, pageIndex, pageSize, filterType]);

  const handleSubmit = useCallback(() => {
    switch (filterType) {
      case ActiveFilters.Applicant:
        dispatch(searchResultsActions.setFilters(applicantFilters));
        break;
      case ActiveFilters.Application:
        dispatch(searchResultsActions.setFilters(applicationFilters));
        break;
      case ActiveFilters.ActivityLicenseApplications:
        dispatch(
          searchResultsActions.setFilters(activityLicenseApplicationFilters)
        );
        break;
      case ActiveFilters.ApplicationCertificate:
        dispatch(
          searchResultsActions.setFilters(applicationCertificateFilters)
        );
        break;
      case ActiveFilters.Company:
        dispatch(searchResultsActions.setFilters(companyFilters));
        break;
      case ActiveFilters.GeneralPractitionerList:
        dispatch(
          searchResultsActions.setFilters(generalPractitionerListFilters)
        );
        break;
      case ActiveFilters.PublicGeneralPractitionerList:
        dispatch(
          searchResultsActions.setFilters(publicGeneralPractitionerListFilters)
        );
        break;

      case ActiveFilters.PublicActivityLicenses:
        dispatch(searchResultsActions.setFilters(activityLicenseFilters));
        break;
      case ActiveFilters.ActivityLicenses:
        dispatch(searchResultsActions.setFilters(officialActivityLicenseFilters));
        break;
      case ActiveFilters.PublicHealthcareEmployees:
        dispatch(
          searchResultsActions.setFilters(publicHealthcareEmployeesFilters)
        );
        break;
      case ActiveFilters.DecisionTHT:
        dispatch(searchResultsActions.setFilters(decisionTHTFilters));
        break;
      case ActiveFilters.DecisionLicense:
        dispatch(searchResultsActions.setFilters(decisionLicenseFilters));
        break;
      case ActiveFilters.InsuranceContract:
        dispatch(searchResultsActions.setFilters(insuranceContractFilters));
        break;
      default:
        break;
    }
    dispatch(searchResultsActions.setIndex(0));
  }, [
    filterType,
    dispatch,
    applicantFilters,
    applicationFilters,
    activityLicenseApplicationFilters,
    applicationCertificateFilters,
    generalPractitionerListFilters,
    publicGeneralPractitionerListFilters,
    activityLicenseFilters,
    officialActivityLicenseFilters,
    publicHealthcareEmployeesFilters,
    companyFilters,
    decisionTHTFilters,
    decisionLicenseFilters,
    insuranceContractFilters
  ]);

  const exportData = (): AxiosPromise => {
    switch (filterType) {
      case ActiveFilters.Applicant:
        return officialUserDataAPI(undefined, getBaseUrl(), API).exportPersons(
          filters as PersonSearchFilter,
          {
            withCredentials: true,
            responseType: "blob"
          }
        );
      case ActiveFilters.GeneralPractitionerList:
        return officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).exportGeneralPractitionerList(
          filters as GeneralPractitionerListSearchFilter,
          {
            withCredentials: true,
            responseType: "blob"
          }
        );
      case ActiveFilters.Company:
        return corporateDataActivityAPI(
          undefined,
          getBaseUrl(),
          API
        ).exportCompanies(filters as CompanySearchFilter, {
          withCredentials: true,
          responseType: "blob"
        });
      case ActiveFilters.ActivityLicenses:
        return officialActivityPermitAPI(
          undefined,
          getBaseUrl(),
          API
        ).exportActivityLicenses(filters as ActivityLicenseSearchFilter, {
          withCredentials: true,
          responseType: "blob"
        });
      case ActiveFilters.Application:
      case ActiveFilters.ActivityLicenseApplications:
      case ActiveFilters.ApplicationCertificate:
      default:
        return Promise.reject();
    }
  };

  const handleExport = useCallback(() => {
    setLoading(true);
    return exportData()
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "eksport.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch))
      .finally(() => setLoading(false));
  }, [filters, filterType, dispatch]);

  const exportKraData = (): AxiosPromise => {
    switch (filterType) {
      case ActiveFilters.Applicant:
        return officialUserDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).exportKraPersons({
          withCredentials: true,
          responseType: "blob"
        });

      case ActiveFilters.ActivityLicenses:
        return officialUserDataAPI(
          undefined,
          getBaseUrl(),
          API
        ).exportKraLicenses({
          withCredentials: true,
          responseType: "blob"
        });
      default:
        return Promise.reject();
    }
  };

  const handleKraExport = useCallback(() => {
    setLoading(true);
    return exportKraData()
      .then((response) => {
        const fileName =
          response?.headers["content-disposition"]?.split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => displayAlert("requestFailed", AlertType.Danger, dispatch))
      .finally(() => setLoading(false));
  }, [filterType, dispatch]);

  const renderSearchResults = () => {
    if (hideResultsAtStart && !filters) {
      return (
        <div className="empty-results d-flex flex-column align-items-center">
          <img
            className="empty-results mt-5 mb-4"
            src={emptyResults}
            alt="Tulemuste nägemiseks tee otsingupäring"
          />
          <p className="empty-results-text text-center fs-14">
            <FormattedMessage
              id="searchPage.emptyResults"
              defaultMessage="Tulemuste nägemiseks tee otsingupäring"
            />
          </p>
        </div>
      );
    }

    return (
      <>
        <h2 className="mt-5 font-weight-normal">
          Otsingutulemused ({ rowCount } tulemust)
        </h2>
        <Card className="position-relative search-table">
          { loading && <Loader absolute /> }
          <>
            { filterType === ActiveFilters.Applicant && (
              <ApplicantResults
                data={data[ActiveFilters.Applicant]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.Application && (
              <ApplicationResults
                data={data[ActiveFilters.Application]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.ActivityLicenseApplications && (
              <ActivityLicenseApplicationResults
                data={data[ActiveFilters.ActivityLicenseApplications]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.ApplicationCertificate && (
              <ApplicationCertificateResults
                data={data[ActiveFilters.ApplicationCertificate]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.GeneralPractitionerList && (
              <GeneralPractitionerListResults
                data={data[ActiveFilters.GeneralPractitionerList]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.Company && (
              <CompanyResults
                data={data[ActiveFilters.Company]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.PublicGeneralPractitionerList && (
              <GeneralPractitionerListResults
                data={data[ActiveFilters.PublicGeneralPractitionerList]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.ActivityLicenses && (
              <ActivityLicenseResults
                data={data[ActiveFilters.ActivityLicenses]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.PublicHealthcareEmployees && (
              <HealthcareEmployeesResults
                data={data[ActiveFilters.PublicHealthcareEmployees]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.PublicActivityLicenses && (
              <ActivityLicenseResults
                data={data[ActiveFilters.PublicActivityLicenses]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.DecisionTHT && (
              <DecisionTHTResults
                data={data[ActiveFilters.DecisionTHT]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.DecisionLicense && (
              <DecisionLicenseResults
                data={data[ActiveFilters.DecisionLicense]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
            { filterType === ActiveFilters.InsuranceContract && (
              <InsuranceContractResults
                data={data[ActiveFilters.InsuranceContract]}
                pageCount={pageCount}
                pageIndex={pageIndex}
              />
            ) }
          </>
        </Card>
      </>
    );
  };

  return (
    <div className="search-application py-5 px-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h1 className="mb-3 font-weight-light">{ title }</h1>{ " " }
        { typeof buttons === "function" ? buttons({ handleExport }) : buttons }
      </div>
      { hideFilter ? null : (
        <Filters
          onSubmit={handleSubmit}
          onExport={handleExport}
          onKraExport={handleKraExport}
          hideFilterSelector={hideFilterSelector}
        />
      ) }
      { renderSearchResults() }
    </div>
  );
};
