import React, { FC, useCallback, useMemo } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import AsyncSelect from "react-select/async";

import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import {
  getPreviousDate,
  isDateInFuture,
  useToday
} from "../../../../Util/DateUtils";
import { getBaseUrl, API } from "../../../../api";
import { generalPractitionerListActions } from "../../../../GeneralPractitionerLists/generalPractitionerListActions";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { formatDate } from "../../../Shared/Application/OverView/ApplicationOverViewUtil";
import fetchApplicantOptions, {
  ApplicantOption
} from "../../../Shared/GeneralPractitioners/fetchApplicantOptions";
import { useGeneralPractitionerListGeneralPractitionerSelector } from "../../../../GeneralPractitionerLists/generalPractitionerListSelector";
import {
  GeneralPractitioner,
  Substitute,
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI
} from "../../../../../api_client/medre_api";
import { getFullName } from "../../../../Util/PersonUtils";

interface Props {
  onClose: () => void;
}

interface FormValues {
  familyDoctor: string | ApplicantOption;
  id: string;
  occupationCode: string;
  activationDate: string;
}

const getVerificationMessage = (person: GeneralPractitioner | Substitute) =>
  `${formatDate(
    getPreviousDate(person.deactivationDate)
  )} on viimane kuupäev, millal ‘${getFullName(
    person
  )}’ nimistu juures töötab.`;

const FamilyDoctor: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { fetchGPList, list } = useGeneralPractitionerList();
  const generalPractitioner =
    useGeneralPractitionerListGeneralPractitionerSelector();
  const { substitute } = list;
  const { control, formState, handleSubmit } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {},
    shouldUnregister: false
  });
  const today = useToday();
  const { isValid, isSubmitting } = formState;

  const verificationMessage = useMemo(() => {
    if (generalPractitioner.id && generalPractitioner.deactivationDate) {
      return getVerificationMessage(generalPractitioner);
    }

    if (substitute?.id) {
      return getVerificationMessage(substitute);
    }
    return "";
  }, [generalPractitioner, substitute]);

  const loadFamilyDoctorOptions = useCallback(
    async (occupationCode: string) =>
      await fetchApplicantOptions({ occupationCode }),
    []
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { familyDoctor, activationDate } = values;
      const { id: personId, occupationCode } = familyDoctor as ApplicantOption;

      const { data: gp } = await officialFamilyListAPI(
        undefined,
        getBaseUrl(),
        API
      ).updateGeneralPractitionerModification(
        list.id,
        {
          id: personId,
          occupationCode,
          activationDate
        },
        {
          withCredentials: true
        }
      );

      dispatch(generalPractitionerListActions.setGeneralPractitioner(gp));

      await fetchGPList(list.id);

      onClose();
    },
    [fetchGPList, list.id, dispatch, onClose]
  );

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <FormattedMessage
            id="GPListAddFamilyDoctorModal.header"
            defaultMessage="Määra nimistu perearst"
          />
        </ModalHeader>
        <ModalBody>
          <FormGroup row={true}>
            <Label
              htmlFor="gpListAddFamilyDoctorModalFamilyDoctor"
              sm={4}
              className="text-right"
            >
              Perearst
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                name="familyDoctor"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({
                  value,
                  onChange
                }: ControllerRenderProps<Record<string, any>>) => (
                  <AsyncSelect
                    inputId="gpListAddFamilyDoctorModalFamilyDoctor"
                    value={value}
                    placeholder={
                      <FormattedMessage
                        id="asyncCustomSelect.search"
                        defaultMessage="Otsi"
                      />
                    }
                    onChange={onChange}
                    loadOptions={loadFamilyDoctorOptions}
                    cacheOptions={true}
                  />
                )}
              />
              <FormText>peremeditsiin</FormText>
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Label
              htmlFor="gpListAddFamilyDoctorModalActivationDate"
              sm={4}
              className="text-right"
            >
              Muutmise kuupäev
            </Label>
            <Col sm={8} className="flex-grow-1">
              <Controller
                name="activationDate"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={(field: ControllerRenderProps<Record<string, any>>) => (
                  <DatePickerComponent
                    id="gpListAddFamilyDoctorModalActivationDate"
                    onDateChange={field.onChange}
                    selectedDate={field.value}
                    placeholder="pp.kk.aaaa"
                    disableFuture={true}
                  />
                )}
              />
              <FormText>{verificationMessage}</FormText>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton
            id="cancel"
            onClick={onClose}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            <FormattedMessage
              id="GPListAddFamilyDoctorModal.submit"
              defaultMessage="Määra perearst"
            />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default FamilyDoctor;
