import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Controller, useForm, ControllerRenderProps } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SecondaryFormattedButton } from "../../../../Component/Button/SecondaryFormattedButton";
import { DatePickerComponent } from "../../../../Component/DatePicker/DatePickerComponent";
import fetchApplicantOptions, {
  ApplicantOption
} from "../../../Shared/GeneralPractitioners/fetchApplicantOptions";
import { getBaseUrl, API } from "../../../../api";
import useGeneralPractitionerList from "../../../Shared/GeneralPractitioners/useGeneralPractitionerList";
import { AlertMessage } from "../../../../Alert/AlertMessage";
import { AlertType } from "../../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../../Alert/alertActions";
import { ModalKeys } from "../../../../Dto/GeneralPractitionerList/GeneralPractitionerList";
import { Loader } from "../../../../Component/Loader/Loader";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyPhysicianListAPI,
  Substitute
} from "../../../../../api_client/medre_api";
import { GeneralPractitionerListDoctorField } from "../../../Shared/GeneralPractitioners/GeneralPractitionerListDoctor";

const titles = {
  [ModalKeys.assignSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.assignSubstituteTitle"
      defaultMessage="Asendaja määramine"
    />
  ),
  [ModalKeys.editSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.editSubstituteTitle"
      defaultMessage="Muuda nimistu asendaja"
    />
  )
};

const buttons = {
  [ModalKeys.assignSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.assignSubstituteButton"
      defaultMessage="Asendaja määramine"
    />
  ),
  [ModalKeys.editSubstitute]: (
    <FormattedMessage
      id="GPListSubstituteModal.editSubstitute"
      defaultMessage="Muuda asendaja"
    />
  )
};

interface Props {
  id: ModalKeys.assignSubstitute | ModalKeys.editSubstitute;
  onClose: () => void;
}

interface FormValues {
  applicant: ApplicantOption;
  id?: string;
  occupationCode?: string;
  activationDate?: string;
  deactivationDate?: string;
}

const SubstituteComponent: FC<Props> = ({ id: modalId, onClose }) => {
  const { id } = useParams<{ id: string }>();
  const isEditing = useMemo(
    () => modalId === ModalKeys.editSubstitute,
    [modalId]
  );
  const [isLoading, setLoading] = useState(isEditing);

  const { watch, setValue, handleSubmit, control, formState, reset } =
    useForm<FormValues>({
      mode: "onChange",
      defaultValues: {
        applicant: {}
      }
    });

  useEffect(() => {
    (async () => {
      if (!isEditing) {
        return;
      }
      const { occupationCode: substituteOccupationCode, ...substitute } = (
        await officialFamilyPhysicianListAPI(
          undefined,
          getBaseUrl(),
          API
        ).getSubstituteModification(id, {
          withCredentials: true
        })
      ).data;
      if (substituteOccupationCode) {
        reset({
          applicant: (
            await fetchApplicantOptions({
              occupationCode: substituteOccupationCode
            })
          ).find(
            ({ occupationCode }: any) =>
              occupationCode === substituteOccupationCode
          ),
          ...substitute
        });
      }
      setLoading(false);
    })();
  }, [isEditing, id, reset]);

  const { isValid, isSubmitting } = formState;
  const { id: personId, applicant } = watch();

  const loadSubstituteOptions = useCallback(
    async (occupationCode: string) =>
      await fetchApplicantOptions({ occupationCode }),
    []
  );

  const handleDeleteApplicant = useCallback(() => {
    setValue("applicant", {});
  }, [setValue]);

  useEffect(() => {
    setValue("id", applicant.id ?? "", { shouldValidate: true });
    setValue("occupationCode", applicant.occupationCode ?? "", {
      shouldValidate: true
    });
  }, [applicant, setValue]);

  const handleChangeActivationDate = useCallback(
    (date: Date) => {
      setValue("activationDate", date, { shouldValidate: true });
    },
    [setValue]
  );

  const { fetchGPList } = useGeneralPractitionerList();
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (values: Substitute) => {
      try {
        await officialFamilyPhysicianListAPI(
          undefined,
          getBaseUrl(),
          API
        ).updateSubstituteModification(id, values, {
          withCredentials: true
        });
        await fetchGPList(id!);
        onClose();
      } catch (error) {
        const alertMessage = <AlertMessage id="requestFailed" />;
        const alert = { id: 0, type: AlertType.Danger, message: alertMessage };
        dispatch(alertActions.addAlert(alert));
      }
    },
    [fetchGPList, id, onClose, dispatch]
  );

  return (
    <Modal isOpen={true} className="gp-lists-base-modal">
      {isLoading && <Loader backdrop={true} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{titles[modalId]}</ModalHeader>
        <ModalBody className="pt-0 pb-0">
          <Card className="mt-3 mb-3">
            <CardHeader className="p-3 border-0 position-relative">
              <Controller
                name="id"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={<input type="hidden" />}
              />
              <Controller
                name="occupationCode"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={<input type="hidden" />}
              />

              <Controller
                name="applicant"
                control={control}
                render={(
                  renderProps: ControllerRenderProps<Record<string, any>>
                ) => (
                  <GeneralPractitionerListDoctorField
                    id="gpListSubstituteModalApplicant"
                    {...renderProps}
                    handleDelete={handleDeleteApplicant}
                    loadOptions={loadSubstituteOptions}
                  />
                )}
              />
            </CardHeader>
          </Card>

          <Card className="mt-3 mb-3">
            <CardHeader className="p-3 border-0 position-relative">
              <FormGroup row={true} className="mb-0">
                <Label
                  htmlFor="gpListSubstituteModalActivationDate"
                  sm={4}
                  className="text-right"
                >
                  Asenduse periood
                </Label>
                <Col sm={4} className="flex-grow-1">
                  <Controller
                    name="activationDate"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({
                      value
                    }: ControllerRenderProps<Record<string, any>>) => (
                      <DatePickerComponent
                        id="gpListSubstituteModalActivationDate"
                        onDateChange={handleChangeActivationDate}
                        selectedDate={value}
                        disabled={!personId}
                        disableFuture={true}
                      />
                    )}
                  />
                </Col>
                <Col sm={4} className="flex-grow-1">
                  <Controller
                    name="deactivationDate"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({
                      onChange,
                      value
                    }: ControllerRenderProps<Record<string, any>>) => (
                      <DatePickerComponent
                        onDateChange={onChange}
                        selectedDate={value}
                        disabled={!personId}
                        disablePast={true}
                      />
                    )}
                  />
                </Col>
              </FormGroup>
            </CardHeader>
          </Card>
        </ModalBody>
        <ModalFooter>
          <SecondaryFormattedButton id="cancel" onClick={onClose} />
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || isSubmitting}
          >
            {buttons[modalId]}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SubstituteComponent;
