import { useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "../rootReducer";
import {
  ServiceArea,
  AllowedServiceArea,
  GeneralPractitioner,
  GeneralPractitionerListLicense,
  GeneralPractitionerList
} from "../../api_client/medre_api";
import { GPListBulkDeletionState } from "../Dto/GeneralPractitionerList/GeneralPractitionerList";

export const useGeneralPractitionerListSelector = () =>
  useSelector<RootState, GeneralPractitionerList>(
    (state) => state.generalPractitionerList.list!
  );

export const useGeneralPractitionerListGeneralPractitionerSelector = () =>
  useSelector<RootState, GeneralPractitioner>(
    (state) => state.generalPractitionerList.generalPractitioner!
  );

export const useGeneralPractitionerListLicenses = () =>
  useSelector<RootState, GeneralPractitionerListLicense[]>(
    (state) => state.generalPractitionerList.licenses!
  );

export const useGeneralPractitionerListServiceAreaSelector = () =>
  useSelector<RootState, ServiceArea>(
    (state) => state.generalPractitionerList.serviceArea!
  );

export const useGeneralPractitionerListAllowedServiceAreasSelector = () =>
  useSelector<RootState, AllowedServiceArea[]>(
    (state) => state.generalPractitionerList.allowedServiceAreas!
  );

export const useGeneralPractitionerListDeletionsSelector = () =>
  useSelector<RootState, GPListBulkDeletionState>(
    (state) => state.generalPractitionerList.deletions
  );

export const useGeneralPractitionerListContacts = () => {
  const { contacts } = useGeneralPractitionerListSelector();

  return useMemo(() => contacts, [contacts]);
};

export const useAllowedServiceAreaOptions = () => {
  const allowedServiceAreas =
    useGeneralPractitionerListAllowedServiceAreasSelector();

  return useMemo(
    () =>
      allowedServiceAreas.map((allowedServiceArea) => ({
        value: allowedServiceArea.id!,
        label: allowedServiceArea.value!,
        deleteOnly: allowedServiceArea.deleteOnly!
      })),
    [allowedServiceAreas]
  );
};
