import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../../../Component/Loader/Loader";
import { GeneralPractitionersDetailedActions } from "./GeneralPractitionersDetailedActions";
import { GPListDetailedHeader } from "../../Shared/GeneralPractitioners/Detailed/GPListDetailedHeader";
import { GPListContacts } from "../../Shared/GeneralPractitioners/Detailed/GPListContacts";
import { GPListEmployees } from "../../Shared/GeneralPractitioners/Detailed/GPListEmployees";
import { GPListAppointmentTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListAppointmentTimes";
import { GPListConsultationTimes } from "../../Shared/GeneralPractitioners/Detailed/GPListConsultationTimes";
import { GPListSubstituteDoctors } from "../../Shared/GeneralPractitioners/Detailed/GPListSubstituteDoctors";
import { GPListSubstitutePeriod } from "../../Shared/GeneralPractitioners/Detailed/GPListSubstitutePeriod";
import { GPListHistory } from "./Detailed/GPListHistory/GPListHistory";
import { GPListLiquidation } from "./Detailed/GPListLiquidation";
import { useDispatch } from "react-redux";
import { generalPractitionerListActions } from "../../../GeneralPractitionerLists/generalPractitionerListActions";
import {
  useGeneralPractitionerListLicenses,
  useGeneralPractitionerListServiceAreaSelector
} from "../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { API, getBaseUrl } from "../../../api";
import { QuickLinks } from "../../../Component/QuickLinks/QuickLinks";
import {
  useOfficialGeneralPractitionersDetailsSectionsList
} from "../../Shared/GeneralPractitioners/GeneralPractitionersDetailedNavigationList";
import { AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI } from "../../../../api_client/medre_api";
import useGeneralPractitionerList from "../../Shared/GeneralPractitioners/useGeneralPractitionerList";

export const GeneralPractitionersDetailed = () => {
  const [modificationsFetched, setModificationsFetched] = useState(false);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { list, fetchGPList } = useGeneralPractitionerList();
  const serviceArea = useGeneralPractitionerListServiceAreaSelector();
  const licenses = useGeneralPractitionerListLicenses();
  const quickLinks = useOfficialGeneralPractitionersDetailsSectionsList(
    list?.status
  );

  const fetchGPListModifications = useCallback(
    async (listId: string) => {
      Promise.all([
        officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).getGeneralPractitionerModification(listId, {
          withCredentials: true
        }),
        officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).getServiceAreaModification(listId, {
          withCredentials: true
        }),
        officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).getLicensesModification(listId, {
          withCredentials: true
        }),

        officialFamilyListAPI(
          undefined,
          getBaseUrl(),
          API
        ).getAllowedServiceAreas(listId, {
          withCredentials: true
        })
      ])
        .then(
          ([
            { data: generalPractitioner },
            { data: modificationServiceArea },
            { data: modificationLicenses },
            { data: allowedServiceAreas }
          ]) => {
            dispatch(
              generalPractitionerListActions.setGeneralPractitioner(
                generalPractitioner
              )
            );
            dispatch(
              generalPractitionerListActions.setServiceArea(
                modificationServiceArea
              )
            );
            dispatch(
              generalPractitionerListActions.setLicenses(modificationLicenses)
            );
            dispatch(
              generalPractitionerListActions.setAllowedServiceAreas(
                allowedServiceAreas
              )
            );
          }
        )
        .finally(() => {
          setModificationsFetched(true);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    window.scrollTo(0, 0);

    fetchGPList(id, true);
    fetchGPListModifications(id);

    return () => {
      dispatch(generalPractitionerListActions.clearList());
    };
  }, [id, dispatch, fetchGPList, fetchGPListModifications]);

  if (!list?.id || !modificationsFetched) {
    return <Loader />;
  }

  return (
    <div className="application-container application-detail p-3">
      <GPListDetailedHeader
        modifiedServiceArea={serviceArea}
        modifiedLicenses={licenses}
      />

      <QuickLinks links={quickLinks} />

      <GeneralPractitionersDetailedActions />

      <GPListContacts />

      <GPListEmployees />

      <GPListAppointmentTimes />

      <GPListConsultationTimes />

      <GPListSubstituteDoctors />

      <GPListSubstitutePeriod />

      <GPListHistory />

      <GPListLiquidation />
    </div>
  );
};
