import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { LocaleMessage } from "../Locale/LocaleMessage";

interface Props {
  id: string;
  // tslint:disable-next-line:no-any
  values?: Record<string, any>;
}

type AlertMessage =
  | "requestFailed"
  | "occupationExists"
  | "applicationDraftDeleteSuccess"
  | "applicationDraftDeleteFailure"
  | "decisionDeleteSuccess"
  | "decisionDeleteFailure"
  | "occupationDraftSaved"
  | "occupationApplied"
  | "specializationExists"
  | "specializationDraftSaved"
  | "specializationApplied"
  | "ehisRequestFailed"
  | "fileUploadSuccessful"
  | "fileUploadFailure"
  | "fileDeleteSuccessful"
  | "fileDeleteFailure"
  | "maxFilesUploadNumberExceeded"
  | "maxFileUploadSizeExceeded"
  | "fileExtensionNotAllowed"
  | "fileNameNotUnique"
  | "noteSaveSuccessful"
  | "noteSaveFailure"
  | "statusUpdateSuccess"
  | "statusUpdateFailure"
  | "tortaFailure"
  | "initiateProceedingSuccess"
  | "initiateProceedingFailure"
  | "continueProceedingSuccess"
  | "continueProceedingFailure"
  | "createDecisionSuccess"
  | "createDecisionFailure"
  | "signDecisionSuccess"
  | "signDecisionFailure"
  | "renewApplicationSuccess"
  | "renewApplicationFailure"
  | "manualPaymentSuccess"
  | "manualPaymentFailure"
  | "applicationAcceptedAutomatically"
  | "fetchUserApplicationsFailure"
  | "personalDataChangeSuccess"
  | "previousNameChangeSuccess"
  | "externalDecisionAddSuccess"
  | "prescriptivePowerAddSuccess"
  | "representationQueryFailed"
  | "companySelectionFailed"
  | "activityLicenseSaveSuccess"
  | "activityLicenseSaveFailed"
  | "activityLicenseFetchFailed"
  | "fetchDetailedBusinessInfoFailed"
  | "applicationForwardingSuccess"
  | "signingSuccess"
  | "signingFailed"
  | "specialistAddedFailure"
  | "specialistAddedSuccess"
  | "specialistRemovedFailure"
  | "specialistRemovedSuccess"
  | "certificateLoadFailed"
  | "cannotDiscardAllServices"
  | "contactInfoUpdateSuccess"
  | "applicationSaveFailed"
  | "representativeUpdateFailed"
  | "fetchDetailedEmployeeEmploymentsInfoFailed"
  | "residencyCompletionSuccess"
  | "residencyCompletionFailed"
  | "automaticAcceptingFailed";

export const AlertMessage = ({ id, ...props }: Props) => {
  const messages: Record<AlertMessage, LocaleMessage> = defineMessages({
    requestFailed: {
      id: "alert.requestFailed",
      defaultMessage: "Päring ebaõnnestus."
    },
    requestSuccess: {
      id: "alert.requestSuccess",
      defaultMessage: "Päring õnnestus"
    },
    occupationExists: {
      id: "alert.occupationExists",
      defaultMessage: "Valitud kutse on teil juba taotletud."
    },
    applicationDraftDeleteSuccess: {
      id: "alert.applicationDraftDeleteSuccess",
      defaultMessage: "Taotluse kustutamine õnnestus."
    },
    applicationDraftDeleteFailure: {
      id: "alert.applicationDraftDeleteFailure",
      defaultMessage: "Taotluse kustutamine ebaõnnestus."
    },
    decisionDeleteSuccess: {
      id: "alert.decisionDeleteSuccess",
      defaultMessage: "Otsuse kustutamine õnnestus."
    },
    decisionDeleteFailure: {
      id: "alert.decisionDeleteFailure",
      defaultMessage: "Otsuse kustutamine ebaõnnestus."
    },
    occupationDraftSaved: {
      id: "alert.occupationDraftSaved",
      defaultMessage:
        "Valitud kutse taotlus on teil pooleli. Poolelioleva kutse taotluse leiate \"Minu taotlused\" lehelt."
    },
    occupationApplied: {
      id: "alert.occupationApplied",
      defaultMessage:
        "Valitud kutse taotlus on teil esitatud. Kutse taotluse hetkeseisu saate jälgida \"Minu taotlused\" lehelt."
    },
    specializationExists: {
      id: "alert.specializationExists",
      defaultMessage: "Valitud eriala on teil juba taotletud."
    },
    specializationDraftSaved: {
      id: "alert.specializationDraftSaved",
      defaultMessage:
        "Valitud eriala taotlus on teil pooleli. Poolelioleva eriala taotluse leiate \"Minu taotlused\" lehelt."
    },
    specializationApplied: {
      id: "alert.specializationApplied",
      defaultMessage:
        "Valitud eriala taotlus on teil esitatud. Eriala taotluse hetkeseisu saate jälgida \"Minu taotlused\" lehelt."
    },
    applicationSaveSuccess: {
      id: "alertMessage.applicationSaveSuccess",
      defaultMessage: "Avaldus edukalt salvestatud"
    },
    applicationSaveFailed: {
      id: "alert.applicationSaveFailed",
      defaultMessage: "Avalduse salvestamine ebaõnnestus"
    },
    activityLicenseFetchFailed: {
      id: "alert.activityLicenseFetchFailed",
      defaultMessage: "Tegevusloa päring ebaõnnestus"
    },
    ehisRequestFailed: {
      id: "alert.ehisRequestFailed",
      defaultMessage:
        "EHIS ei vasta päringule. Palun proovige hiljem uuesti või sisestage hariduse info käsitsi."
    },
    fileUploadSuccessful: {
      id: "alert.fileUploadSuccesful",
      defaultMessage: "Failide üleslaadimine õnnestus."
    },
    fileUploadFailure: {
      id: "alert.fileUploadFailure",
      defaultMessage: "Failide üleslaadimine ebaõnnestus."
    },
    fileDeleteSuccessful: {
      id: "alert.fileDeleteSuccessful",
      defaultMessage: "Faili kustutamine õnnestus."
    },
    fileDeleteFailure: {
      id: "alert.fileDeleteFailure",
      defaultMessage: "Faili kustutamine ebaõnnestus."
    },
    maxFilesUploadNumberExceeded: {
      id: "alert.maxFilesUploadNumberExceeded",
      defaultMessage:
        "Maksimaalselt lubatud {maxFilesCount, plural, one {# fail} other {# faili}}."
    },
    maxFileUploadSizeExceeded: {
      id: "alert.maxFileUploadSizeExceeded",
      defaultMessage: "Maksimaalne lubatud faili suurus 30MB."
    },
    fileExtensionNotAllowed: {
      id: "alert.fileExtensionNotAllowed",
      defaultMessage: "Failitüüp ei ole lubatud."
    },
    fileNameNotUnique: {
      id: "alert.fileNameNotUnique",
      defaultMessage: "Sama nimega fail juba lisatud."
    },
    noteSaveSuccessful: {
      id: "alert.noteSaveSuccessful",
      defaultMessage: "Märkmete salvestamine õnnestus."
    },
    noteSaveFailure: {
      id: "alert.noteSaveFailure",
      defaultMessage: "Märkmete salvestamine ebaõnnestus."
    },
    statusUpdateSuccess: {
      id: "alert.statusUpdateSuccess",
      defaultMessage: "Staatuse muutmine õnnestus."
    },
    statusUpdateFailure: {
      id: "alert.statusUpdateFailure",
      defaultMessage: "Staatuse muutmine ebaõnnestus."
    },
    tortaFailure: {
      id: "alert.tortaFailure",
      defaultMessage:
        "Üks või mitu taotluses märgitud isikut ei ole kantud töötamise registrisse."
    },
    initiateProceedingSuccess: {
      id: "alert.initiateProceedingSuccess",
      defaultMessage: "Menetluse alustamine õnnestus."
    },
    initiateProceedingFailure: {
      id: "alert.initiateProceedingFailure",
      defaultMessage: "Menetluse alustamine ebaõnnestus."
    },
    continueProceedingSuccess: {
      id: "alert.continueProceedingSuccess",
      defaultMessage: "Menetluse jätkamine õnnestus."
    },
    continueProceedingFailure: {
      id: "alert.continueProceedingFailure",
      defaultMessage: "Menetluse jätkamine ebaõnnestus."
    },
    signDecisionSuccess: {
      id: "alert.signDecisionSuccess",
      defaultMessage: "Otsuse allkirjastamine õnnestus."
    },
    createDecisionSuccess: {
      id: "alert.createDecisionSuccess",
      defaultMessage: "Otsuse loomine õnnestus."
    },
    createDecisionFailure: {
      id: "alert.createDecisionFailure",
      defaultMessage: "Otsuse loomine ebaõnnestus."
    },
    signDecisionFailure: {
      id: "alert.signDecisionFailure",
      defaultMessage: "Otsuse allkirjastamine ebaõnnestus."
    },
    renewApplicationSuccess: {
      id: "alert.renewApplicationSuccess",
      defaultMessage: "Taotluse täiendamine õnnestus."
    },
    renewApplicationFailure: {
      id: "alert.renewApplicationFailure",
      defaultMessage: "Taotluse täiendamine ebaõnnestus."
    },
    manualPaymentSuccess: {
      id: "alert.manualPaymentSuccess",
      defaultMessage: "Taotluse tasutuks märkimine õnnestus."
    },
    manualPaymentFailure: {
      id: "alert.manualPaymentFailure",
      defaultMessage: "Taotluse tasutuks märkimine ebaõnnestus."
    },
    applicationAcceptedAutomatically: {
      id: "alert.applicationAcceptedAutomatically",
      defaultMessage: "Taotlus rahuldati automaatselt."
    },
    fetchUserApplicationsFailure: {
      id: "alert.fetchUserApplicationsFailure",
      defaultMessage: "Taotluste päring ebaõnnestus."
    },
    personalDataChangeSuccess: {
      id: "alert.personalDataChangeSuccess",
      defaultMessage: "Isikuandmed muudeti edukalt."
    },
    previousNameChangeSuccess: {
      id: "alert.previousNameChangeSuccess",
      defaultMessage: "Eelmine nimi lisati edukalt."
    },
    externalDecisionAddSuccess: {
      id: "alert.externalDecisionAddSuccess",
      defaultMessage: "Süsteemiväline otsus lisati edukalt."
    },
    prescriptivePowerAddSuccess: {
      id: "alert.prescriptivePowerAddSuccess",
      defaultMessage: "Retseptiõigus lisati edukalt."
    },
    representationQueryFailed: {
      id: "alert.representationQueryFailed",
      defaultMessage: "Ettevõtete päring ebaõnnestus."
    },
    companySelectionFailed: {
      id: "alert.companySelectionFailed",
      defaultMessage: "Ettevõtete muutmine ebaõnnestus."
    },
    activityLicenseSaveSuccess: {
      id: "alert.activityLicenseSaveSuccess",
      defaultMessage: "Tegevusloa salvestamine õnnestus."
    },
    activityLicenseSaveFailed: {
      id: "alert.activityLicenseSaveFailed",
      defaultMessage: "Tegevusloa salvestamine ebaõnnestus."
    },
    fetchDetailedBusinessInfoFailed: {
      id: "alert.fetchDetailedBusinessInfoFailed",
      defaultMessage: "Ettevõtte detailandmete päring ebaõnnestus."
    },
    fetchDetailedEmployeeEmploymentsInfoFailed: {
      id: "alert.fetchDetailedEmployeeInfoFailed",
      defaultMessage: "Viga töötaja töökoha andmete laadimisel."
    },
    applicationSubmitted: {
      id: "alert.applicationSubmitted",
      defaultMessage: "Taotlus esitatud."
    },
    activityLicenseSubmitFailed: {
      id: "alert.activityLicenseSubmitFailed",
      defaultMessage: "Taotluse esitamine ebaõnnestus."
    },
    invalidSuspensionDurationException: {
      id: "alert.invalidSuspensionDurationException",
      defaultMessage: "Periood üle kõigi peatamiste on üle lubatud piiri."
    },
    applicationForwardingSuccess: {
      id: "alert.applicationForwardingSuccess",
      defaultMessage: "Taotlus edukalt ametnikule suunatud."
    },
    saveEducationSuccess: {
      id: "alert.saveEducationRequestSuccess",
      defaultMessage: "Hariduse salvestamine õnnetus."
    },
    saveEducationSuccessWithPrescriptionPower: {
      id: "alert.saveEducationRequestSuccessWithPrescriptionPower",
      defaultMessage: "EHIS hariduse salvestamine õnnetus. Isikule väljastati ka eriõe retseptiõigus, näed seda \"Retseptiõigus\" sektsioonist."
    },
    saveEducationSuccessWithFailedPrescriptionCheck: {
      id: "alert.saveEducationRequestSuccessWithFailedPrescriptionCheck",
      defaultMessage: "EHIS hariduse salvestamine õnnetus. Eriõe retseptiõiguse väljastamisega seonduvaid kontrolle ei õnnestunud teostada. Vajadusel väljasta eriõe retseptiõigus \"Retseptiõigus\" sektsioonist."
    },
    saveEducationFailure: {
      id: "alert.saveEducationRequestFailed",
      defaultMessage: "Uue hariduse lisamine ebaõnnestus."
    },
    deleteEducationFailure: {
      id: "alert.deleteEducationRequestFailed",
      defaultMessage: "Hariduse kustutamisel ilmnes viga."
    },
    applicationForwardingFailure: {
      id: "alert.applicationForwardingFailure",
      defaultMessage: "Taotluse suunamine ametnikule ebaõnnestus."
    },
    signingSuccess: {
      id: "alert.signingSuccess",
      defaultMessage: "Allkirjastamine õnnestus."
    },
    signingFailed: {
      id: "alert.signingFailed",
      defaultMessage: "Allkirjastamine ebaõnnestus."
    },
    specialistAddedFailure: {
      id: "alert.specialistAddedFailure",
      defaultMessage: "Viga spetsialisti lisamisel."
    },
    specialistAddedSuccess: {
      id: "alert.specialistAddedSuccess",
      defaultMessage: "Spetsialist lisatud."
    },
    specialistRemovedFailure: {
      id: "alert.specialistRemovedFailure",
      defaultMessage: "Viga spetsialisti eemaldamisel."
    },
    specialistRemovedSuccess: {
      id: "alert.specialistRemovedSuccess",
      defaultMessage: "Spetsialist eemaldatud."
    },
    certificateLoadFailed: {
      id: "alert.certificateLoadFailed",
      defaultMessage: "ID kaarti ei leitud."
    },
    employeesAdded: {
      id: "alert.employeesAdded",
      defaultMessage: "Töötaja(d) lisatud."
    },
    employeesNotAdded: {
      id: "alert.employeesNotAdded",
      defaultMessage:
        "Üks või mitu taotluses märgitud isikut ei ole kantud töötamise registrisse"
    },
    employeeDeleteSuccess: {
      id: "alert.employeeDeleteSuccess",
      defaultMessage: "Töötaja kustutamine õnnestus."
    },
    employeeDeleteNotAllowed: {
      id: "alert.employeeDeleteNotAllowed",
      defaultMessage:
        "Antud isiku töösuhte muutmine ilma tegevusloa muutmiseta ei ole võimalik."
    },
    cannotDiscardAllServices: {
      id: "alert.cannotDiscardAllServices",
      defaultMessage: "Kõikidest teenustest tegevuskohal ei saa loobuda."
    },
    contactInfoUpdateSuccess: {
      id: "alert.contactInfoUpdateSuccess",
      defaultMessage: "Kontaktandmete uuendamine õnnestus."
    },
    downloadFailed: {
      id: "alert.downloadFailed",
      defaultMessage: "Allalaadimine ebaõnnestus."
    },
    representativeUpdateSuccess: {
      id: "alert.representativeUpdateSuccess",
      defaultMessage: "Volituse muutmine õnnestus."
    },
    representativeUpdateFailed: {
      id: "alert.representativeUpdateFailed",
      defaultMessage: "Volituse muutmine ebaõnnestus."
    },
    representativeAdditionFailed: {
      id: "alert.representativeAdditionFailed",
      defaultMessage: "Volituse lisamine ebaõnnestus."
    },
    representativeAdditionSuccess: {
      id: "alert.representativeAdditionSuccess",
      defaultMessage: "Volitus on edukalt lisatud."
    },
    personalDataUpdateFailed: {
      id: "alert.personalDataUpdateFailed",
      defaultMessage: "Isikuandmete värskendamine ebaõnnestus"
    },
    rrRequestFailed: {
      id: "alert.rrRequestFailed",
      defaultMessage: "Rabbit ei vastanud ID-ga isiku otsimisel"
    },
    applicationHideSuccess: {
      id: "alert.applicationHideSuccess",
      defaultMessage: "Taotlus on peidetud"
    },
    applicationHideFailed: {
      id: "alert.applicationHideFailed",
      defaultMessage: "Taotluse peitmine ebaõnnestus"
    },
    representativePerson_has_right_related_to_selected_list: {
      id: "alert.representativePerson_has_right_related_to_selected_list",
      defaultMessage:
        "Isikul on juba valitud nimistuga seotud esindusõigus olemas. Nimistuga seotud volitusest ülevaate saamiseks, muutmiseks või peatamiseks otsi isik esindajate nimekirjast üles."
    },
    representativePerson_has_right_of_representation: {
      id: "alert.representativePerson_has_right_of_representation",
      defaultMessage:
        "Isikul on juba esindusõigus olemas. Volitusest ülevaate saamiseks, muutmiseks või peatamiseks otsi isik esindajate nimekirjast üles."
    },
    person_has_right_granted_in_Business_Register: {
      id: "alert.person_has_right_granted_in_Business_Register",
      defaultMessage:
        "Isikul on Äriregistris antud ettevõtte esindusõigus olemas."
    },
    representativeDeleteSuccess: {
      id: "alert.representativeDeleteSuccess",
      defaultMessage: "Volitus on edukalt eemaldatud."
    },
    representativeDeleteFailed: {
      id: "alert.representativeDeleteFailed",
      defaultMessage: "Volituse eemaldamine ebaõnnestus."
    },
    representativeSuspendSuccess: {
      id: "alert.representativeSuspendSuccess",
      defaultMessage: "Volitus on edukalt peatatud."
    },
    representativeSuspendFailed: {
      id: "alert.representativeSuspendFailed",
      defaultMessage: "Volituse peatamine ebaõnnestus."
    },
    gpContactSizeUpdateSuccess: {
      id: "alert.gpContactSizeUpdateSuccess",
      defaultMessage: "Nimistu suurus on edukalt uuendatud."
    },
    gpContactSizeUpdateFailed: {
      id: "alert.gpContactSizeUpdateFailed",
      defaultMessage: "Nimistu suuruse uuendamine ebaõnnestus."
    },
    personExists: {
      id: "alert.personExists",
      defaultMessage:
        "Sisestatud isikukoodiga isik on MEDRE andmebaasisi olemas. Olemasoleva isiku leiad THT taotleja otsingu kaudu."
    },
    specialNursePrescriptionAddedSuccessfully: {
      id: "alert.specialNursePrescriptionAddedSuccessfully",
      defaultMessage: "Eriõe retseptiõigus lisati edukalt."
    },
    specialNursePrescriptionAddedFailed: {
      id: "specialNursePrescriptionAddedFailed",
      defaultMessage: "Eriõe retseptiõigus lisamine ebaõnnestus."
    },
    requiredFieldsEmpty: {
      id: "alert.requiredFieldsEmpty",
      defaultMessage: "Kohustuslikud väljad on tühjad."
    },
    residencyCompletionSuccess: {
      id: "alert.residencyCompletionSuccess",
      defaultMessage: "Residentuuri lõpetatuks märkimine õnnestus."
    },
    residencyCompletionFailed: {
      id: "alert.residencyCompletionFailed",
      defaultMessage: "Residentuuri lõpetatuks märkimine ebaõnnestus."
    },
    changeRequestSucceeded: {
      id: "alert.changeRequestSucceeded",
      defaultMessage: "Tehtud muudatuste salvestamine õnnestus."
    },
    changeRequestFailed: {
      id: "alert.changeRequestFailed",
      defaultMessage: "Tehtud muudatuste salvestamine ebaõnnestus."
    },
    automaticAcceptingFailed: {
      id: "alert.automaticAcceptingFailed",
      defaultMessage: "Taotlus ei vastanud automaatse menetlemise tingimustele."
    }
  });

  return <FormattedMessage {...messages[id as AlertMessage]} {...props} />;
};
