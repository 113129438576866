import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AxiosResponse } from "axios";

import { API, getBaseUrl } from "../../../api";
import { useGeneralPractitionerListSelector } from "../../../GeneralPractitionerLists/generalPractitionerListSelector";
import { generalPractitionerListActions } from "../../../GeneralPractitionerLists/generalPractitionerListActions";
import { AlertMessage } from "../../../Alert/AlertMessage";
import { AlertType } from "../../../Dto/Alert/AlertItem";
import { alertActions } from "../../../Alert/alertActions";
import { RootState } from "../../../rootReducer";
import { groupBy } from "../../../Util/ArrayUtil";
import {
  AmetnikuPerearstiNimistudTeenusApiFactory as officialFamilyListAPI,
  AvalikPerearstiNimistudTeenusApiFactory as publicGpDirectoryAPI,
  GeneralPractitionerList,
  GeneralPractitionerListEmployee,
  GeneralPractitionerListSubstituteDoctor,
  PerearstiNimistudTeenusApiFactory as doctorDirectoryServiceAPI
} from "../../../../api_client/medre_api";
import { useAuthorized } from "../../../Hook/usePortal";

export const mapGPList = (response: AxiosResponse<GeneralPractitionerList>) => {
  const { employees, substituteDoctors, appointments, licenses, contacts } =
    response.data;
  const employeesMap = new Map<string, GeneralPractitionerListEmployee>(
    employees?.map((employee) => [employee.id, employee])
  );

  const substituteDoctorsMap = new Map<
    string,
    GeneralPractitionerListSubstituteDoctor
  >(substituteDoctors.map((doctor) => [doctor.id as string, doctor]));

  const appointmentsGroupByLocationId = groupBy(appointments, "locationId");

  const locationContacts = contacts.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.locationId]: currentValue.address ?? ""
    }),
    {}
  );

  return {
    ...response.data,
    employeesMap,
    substituteDoctorsMap,
    appointmentsGroup: appointmentsGroupByLocationId,
    locationContacts
  };
};

const useGeneralPractitionerList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const list = useGeneralPractitionerListSelector();
  const isPortal = useSelector<RootState, boolean>(
    (state) => state.config.isPortal!
  );
  const isAuthorized = useAuthorized();

  const fetchGpData = useCallback(
    (listId: string, liteLoading = false) =>
      isPortal
        ? isAuthorized
          ? doctorDirectoryServiceAPI(
              undefined,
              getBaseUrl(),
              API
            ).getGeneralPractitionerList3(listId, liteLoading? 'lite': undefined, {
              withCredentials: true
            })
          : publicGpDirectoryAPI(
              undefined,
              getBaseUrl(),
              API
            ).getGeneralPractitionerList(listId, liteLoading? 'lite': undefined, {
              withCredentials: true
            })
        : officialFamilyListAPI(
            undefined,
            getBaseUrl(),
            API
          ).getGeneralPractitionerList1(listId, liteLoading? 'lite': undefined, {
            withCredentials: true
          }),
    [isPortal, isAuthorized]
  );

  //isAuthorized only if isAuthorized === true
  const updateGpData = useCallback(
    (listId: string, body: GeneralPractitionerList) =>
      isPortal
        ? isAuthorized
          ? doctorDirectoryServiceAPI(
              undefined,
              getBaseUrl()
            ).updateGeneralPractitionerList1(listId, body, {
              withCredentials: true
            })
          : publicGpDirectoryAPI(
              undefined,
              getBaseUrl()
              //TODO There is no UPDATE function
              //Error
            ).getGeneralPractitionerList(listId, 'lite',{
              withCredentials: true
            })
        : officialFamilyListAPI(
            undefined,
            getBaseUrl()
          ).updateGeneralPractitionerList(listId, body, {
            withCredentials: true
          }),
    [isPortal, isAuthorized]
  );

  const getURL = useCallback(
    (listId) => {
      //getGeneralPractitionerList3
      const partialUrl = `/general-practitioner-lists/${listId}`;
      if (isPortal) {
        //getGeneralPractitionerList public
        return `${isAuthorized ? "" : "/public"}${partialUrl}`;
      }
      //getGeneralPractitionerList1
      return `/official${partialUrl}`;
    },
    [isPortal, isAuthorized]
  );

  const fetchGPList = useCallback(
    async (listId: string, liteLoading = false): Promise<GeneralPractitionerList> => {
      setLoading(true);

      try {
        const response = mapGPList(await fetchGpData(listId, liteLoading));
        dispatch(generalPractitionerListActions.setList(response));
        return response;
      } catch (error) {
        if ((error as Record<string, any>)?.response?.status !== 400) {
          history.push("/general-practitioner-lists");
        }
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history]
  );

  const updateGPList = useCallback(
    async (
      body: GeneralPractitionerList,
      cb: () => void,
      messageIds?: Record<string, string>
    ) => {
      setLoading(true);
      let alertMessage = (
        <AlertMessage id={messageIds?.success ?? "requestSuccess"} />
      );
      let alert = { id: 0, type: AlertType.Success, message: alertMessage };
      try {
        const response = mapGPList(await updateGpData(list.id, body));
        dispatch(generalPractitionerListActions.setList(response));
        cb();
      } catch (e) {
        alertMessage = (
          <AlertMessage id={messageIds?.failed ?? "requestFailed"} />
        );
        alert = { id: 0, type: AlertType.Danger, message: alertMessage };
      } finally {
        setLoading(false);
        dispatch(alertActions.addAlert(alert));
      }
    },
    [dispatch, list]
  );

  return { list, loading, fetchGPList, updateGPList };
};

export default useGeneralPractitionerList;
