import React, { ReactElement, useEffect, useState } from "react";
import axios, { Canceler } from "axios-jsonp-pro";
import { FormattedMessage } from "react-intl";
import { FormGroup, Label } from "reactstrap";
import {
  AsyncTypeahead,
  TypeaheadMenu,
  TypeaheadMenuProps
} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

import {
  InAddress,
  InAddressApartment
} from "../../../../../Dto/ContactInfo/Address/InAddress";
import {
  Option,
  SingleSelect
} from "../../../../../Component/Select/SingleSelect";
import { useWindowWidthSize } from "../../../../../Hook/useWindowsSize";
import { IN_ADS_ADDRESS_URL, MOBILE_MAX_WIDTH } from "../../../../../Constants";
import { FormattedErrorMessage } from "../../../../../Component/ErrorField/FormattedErrorMessage";
import { ContactInfoForm } from "../../../../../Dto/ContactInfo/ContactInfoForm";
import { ContactInfoFM } from "../../../../../Messages/ContactInfoFM";
import "./AdsAddressInput.scss";
import { validateLength } from "../../../Validation/fieldValidator";

interface Props {
  formKey?: number;
  fullAddress?: string;
  adsAdrID?: string;
  zipCode?: string;
  RRAddressText?: string;
  updateContactInfo: (field: keyof ContactInfoForm, value?: string) => void;
  setIsAddressValid: (value?: boolean) => void;
  isForceValidation?: boolean;
  isMandatory?: boolean;
  disabled?: boolean;
  addressLabel?: ReactElement;
  addressContainerClassName?: string;
  zipCodeClassName?: string;
  isCertificateIssueAddress?: boolean;
}

export const AdsAddressInput = ({
  formKey,
  fullAddress,
  adsAdrID,
  zipCode,
  setIsAddressValid,
  isForceValidation,
  RRAddressText,
  isMandatory,
  disabled,
  updateContactInfo,
  addressLabel,
  addressContainerClassName,
  zipCodeClassName,
  isCertificateIssueAddress
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<InAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<InAddress>({
    taisaadress: fullAddress
  });
  const [selectedApartment, setSelectedApartment] =
    useState<InAddressApartment>();
  const [isAddressInputValid, setIsAddressInputValid] = useState<boolean>();
  const [isApartmentInputValid, setIsApartmentInputValid] = useState<boolean>();
  const [addressLength, setAddressLength] = useState<number>(0);

  const isMobile = useWindowWidthSize() <= MOBILE_MAX_WIDTH;

  let cancel: Canceler;

  useEffect(() => {
    if (formKey !== 0) {
      updateContactInfo("fullAddress", undefined);
      updateContactInfo("zipCode", undefined);
      setIsAddressInputValid(undefined);
    }
  }, [formKey]);

  useEffect(() => {
    if (isAddressInputValid && disabled) {
      setIsAddressInputValid(undefined);
    }
  }, [isAddressInputValid, disabled]);

  useEffect(() => {
    if (
      isForceValidation &&
      isAddressInputValid &&
      isApartmentInputValid === undefined
    ) {
      setIsApartmentInputValid(false);
    }
  }, [isForceValidation, isAddressInputValid, isApartmentInputValid]);

  useEffect(() => {
    if (isMandatory) {
      setIsAddressValid(
        (isAddressInputValid && isApartmentInputValid) ||
          (isAddressInputValid === undefined && validateLength(fullAddress))
      );
    } else {
      setIsAddressValid(
        (isAddressInputValid && isApartmentInputValid) ||
          isAddressInputValid === undefined
      );
    }
  }, [
    isAddressInputValid,
    isApartmentInputValid,
    setIsAddressValid,
    fullAddress,
    isMandatory
  ]);

  const apartmentOptions = selectedAddress?.appartments?.map((apartment) => ({
    value: apartment.ads_oid,
    label: apartment.tahis
  }));

  useEffect(() => {
    setSelectedAddress((sa) => {
      return { ...sa, taisaadress: fullAddress };
    });
  }, [fullAddress]);

  useEffect(() => {
    if (adsAdrID && !disabled && adsAdrID !== "mobile") {
      axios
        .jsonp(IN_ADS_ADDRESS_URL, {
          params: { adrid: adsAdrID }
        })
        // tslint:disable-next-line:no-any
        .then((res: any) => {
          const [address] = res.addresses as InAddress[];
          if (address) {
            const appartment = {
              adob_id: address.kort_adob_id!,
              adr_id: address.kort_adr_id!,
              ads_oid: address.kort_ads_oid!,
              koodaadress: address.koodaadress!,
              tahis: address.kort_nr!,
              tunnus: address.tunnus!,
              unik: address.unik!
            };
            const taisaddress =
              address.taisaadress ||
              [
                address.maakond,
                address.omavalitsus,
                address.asustusyksus,
                `${address.liikluspind} ${address.aadress_nr}`
              ].join(", ");
            setSelectedAddress({
              ...address,
              taisaadress: taisaddress,
              appartments: !!address.appartments ? [appartment] : undefined
            });
            setSelectedApartment(appartment);
            setIsAddressInputValid(true);
            setIsApartmentInputValid(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (query: string) => {
    setLoading(true);

    if (cancel) {
      cancel();
    }

    const params = {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      address: query,
      appartment: 0,
      unik: 1,
    };
    axios
      .jsonp(IN_ADS_ADDRESS_URL, { params })
      // tslint:disable-next-line:no-any
      .then((result: any) => {
        let addresses = result.addresses;
        if (addresses) {
          addresses = addresses.filter((a: InAddress) => {
            return a.unik !== "0" || a.liikVal === "EHAK";
          });
        }
        setOptions(addresses);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (value: string) => {
    // eslint-disable-next-line no-control-regex
    value = value.replace(/[^\x00-\xFF|ć|ś|ł|š|ž|Ž|Š]/g, "");
    setAddressLength(value.length);
    setSelectedAddress({});
    setOptions([]);
    if (value.length) {
      updateContactInfo("fullAddress", value);
      setIsAddressInputValid(false);
    } else {
      updateContactInfo("fullAddress", undefined);
      updateContactInfo("zipCode", undefined);
      setIsAddressInputValid(undefined);
    }
  };

  function updateAddressInfo(chosenAddress: InAddress) {
    updateContactInfo("fullAddress", chosenAddress.taisaadress);
    updateContactInfo("apartmentNumber", undefined);
    updateContactInfo("countryCode", "EST");
    updateContactInfo("countyName", chosenAddress.maakond);
    updateContactInfo("ehakmk", chosenAddress.ehakmk);
    updateContactInfo("parishName", chosenAddress.omavalitsus);
    updateContactInfo("ehakov", chosenAddress.ehakov);
    updateContactInfo("settlementName", chosenAddress.asustusyksus);
    updateContactInfo("ehak", chosenAddress.ehak);
    updateContactInfo("adsAdrID", chosenAddress.adr_id);
    updateContactInfo("adsOid", chosenAddress.ads_oid);
    updateContactInfo("zipCode", chosenAddress.sihtnumber);
    updateContactInfo("adrCode", chosenAddress.koodaadress);
  }

  async function onAddressChange(addresses: InAddress[]) {
    let chosenAddress = addresses[0];

    if (chosenAddress) {
      if (
        chosenAddress.liikVal !== "EHITIS" &&
        chosenAddress.liikVal !== "EHITISHOONE"
      ) {
        setIsAddressInputValid(false);
      } else {
        setIsAddressInputValid(true);
      }
      if (chosenAddress.appartments && isCertificateIssueAddress) {
        setIsApartmentInputValid(undefined);
      } else {
        setIsApartmentInputValid(true);
      }
      setSelectedAddress(chosenAddress);
      await updateAddressInfo(chosenAddress);
    }

    setSelectedApartment(undefined);
  }

  const handleApartmentChange = (option: Option) => {
    let apartment = selectedAddress?.appartments?.filter(
      (a) => a.ads_oid === option.value
    )[0];
    setSelectedApartment(apartment);
    updateContactInfo("apartmentNumber", apartment?.tahis);
    updateContactInfo("adsAdrID", apartment?.adr_id);
    updateContactInfo("adsOid", apartment?.ads_oid);
    updateContactInfo("adrCode", apartment?.koodaadress);
    setIsApartmentInputValid(!!apartment);
  };

  const getLabel = (a: InAddress) => a.taisaadress || "";

  const showApartmentCheckBox = () => {
    if (
      isAddressInputValid &&
      !disabled &&
      isCertificateIssueAddress &&
      (!RRAddressText ||
        (RRAddressText && RRAddressText !== selectedAddress?.taisaadress))
    ) {
      return !!selectedAddress?.appartments;
    }
    return false;
  };

  const renderMenu = (
    results: InAddress[],
    menuProps: TypeaheadMenuProps<InAddress>
  ) =>
    !results.length ? null : <TypeaheadMenu {...menuProps} options={results} />;

  const addressNotPreciseEnough =
    selectedAddress?.liikVal !== "EHITIS" &&
    selectedAddress?.liikVal !== "EHITISHOONE";
  return (
    <>
      <div
        className={
          "w-100 m-0 flex-nowrap " +
          addressContainerClassName +
          (isMobile ? "" : " form-inline")
        }
      >
        <Label htmlFor="adsAddressInputFullAddress">
          {addressLabel ?? <ContactInfoFM id="address" />}
        </Label>
        <FormGroup className="ads-form-group">
          <div className="input-wrapper">
            <AsyncTypeahead
              onInputChange={(e) => handleInputChange(e)}
              selected={selectedAddress ? [selectedAddress] : []}
              id="adsAddressInputFullAddress"
              inputProps={{
                id: "fullAddress"
              }}
              onChange={onAddressChange}
              onSearch={onSearch}
              delay={700}
              useCache={false}
              highlightOnlyResult={true}
              renderMenu={renderMenu}
              minLength={4}
              isValid={!loading && isAddressInputValid}
              isInvalid={!loading && isAddressInputValid === false}
              isLoading={loading}
              labelKey={(a) => `${getLabel(a)}`}
              options={options}
              filterBy={(option, p) => true}
              disabled={disabled}
            />
            {!loading &&
              isAddressInputValid === false &&
              addressLength > 3 &&
              !addressNotPreciseEnough && (
                <div className="d-block w-100 pt-1">
                  <FormattedErrorMessage id="address" />
                </div>
              )}
            {!loading &&
              isAddressInputValid === false &&
              addressLength > 3 &&
              addressNotPreciseEnough && (
                <div className="d-block w-100 pt-1">
                  <FormattedErrorMessage id="addressNotPreciseEnough" />
                </div>
              )}
          </div>
          {showApartmentCheckBox() && (
            <div className="apartment-select-form-group">
              <SingleSelect
                options={apartmentOptions || []}
                placeholder={<></>}
                value={
                  {
                    value: selectedApartment?.ads_oid,
                    label: selectedApartment?.tahis
                  } as Option
                }
                handleOptionChange={handleApartmentChange}
                className="apartment-select"
                isValid={isApartmentInputValid}
                isInValid={isApartmentInputValid === false}
              />
              {showApartmentCheckBox() && isApartmentInputValid === false && (
                <div className="apartment-select-container">
                  <div className="apartment-select apartment-select-error-field">
                    <FormattedErrorMessage id="mandatoryField" />
                  </div>
                </div>
              )}
            </div>
          )}
        </FormGroup>
      </div>
      <div className="zip-code-container">
        <p className={"zip-code " + zipCodeClassName}>
          <FormattedMessage
            id="contactInfoEdit.zipCode"
            defaultMessage="Postiindeks"
          />
          {" " + (zipCode || "")}
        </p>
      </div>
    </>
  );
};
