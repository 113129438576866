import React from "react";
import { Link } from "react-router-dom";
import {
  GeneralPractitionerListDoctor,
  getGPDoctorLabel
} from "./GeneralPractitionerListDoctor";
import { formatDate } from "../Application/OverView/ApplicationOverViewUtil";
import GeneralPractitionerListServiceArea from "./GeneralPractitionerListServiceArea";
import GeneralPractitionerListVacanciesSize from "./GeneralPractitionerListVacanciesSize";
import GeneralPractitionerListStatus from "../../Official/GeneralPractitioners/GeneralPractitionerListStatus";
import { ReactTableColumn } from "../../../Component/Table/ReactTable";
import { FormattedMessage } from "react-intl";
import { DetailViewLinkButton } from "../../../Component/Button/LinkButton";
import {
  GeneralPractitioner,
  Substitute,
  GeneralPractitionerList
} from "../../../../api_client/medre_api";
import { getFullName } from "../../../Util/PersonUtils";

const generalPractitionerListColumnsObj: Record<
  string,
  ReactTableColumn<GeneralPractitionerList>
> = {
  code: {
    id: "code",
    sticky: true,
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.code"
        defaultMessage="Nimistu nr"
      />
    ),
    accessor: "code",
    width: 100
  },
  generalPractitioner: {
    id: "generalPractitioner.lastName",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.generalPractitionerLastName"
        defaultMessage="Perearst"
      />
    ),
    accessor: "generalPractitioner",
    width: 0,
    Cell: ({ value }) =>
      value?.id ? <GeneralPractitionerListDoctor data={value} /> : null
  },
  substitute: {
    id: "substitute.lastName",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.substituteLastName"
        defaultMessage="Asendaja"
      />
    ),
    accessor: "substitute",
    width: 150,
    Cell: ({ value }) =>
      value?.id ? <GeneralPractitionerListDoctor data={value} /> : null
  },
  actualPatientsSize: {
    id: "actualPatientsSize",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.actualPatientsSize"
        defaultMessage="Suurus"
      />
    ),
    accessor: "actualPatientsSize",
    width: 0
  },
  vacanciesSize: {
    id: "vacanciesSize",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.vacanciesSize"
        defaultMessage="Vabu kohti"
      />
    ),
    accessor: "vacanciesSize",
    width: 0,
    Cell: ({ value }) => (
      <GeneralPractitionerListVacanciesSize vacanciesSize={value} />
    )
  },
  activationDate: {
    id: "activationDate",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.activationDate"
        defaultMessage="Aktiveerimise kuupäev"
      />
    ),
    accessor: "activationDate",
    width: 0,
    Cell: ({ value }) => formatDate(value)
  },
  serviceArea: {
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.serviceArea"
        defaultMessage="Teeninduspiirkond"
      />
    ),
    accessor: "serviceArea",
    width: 0,
    disableSortBy: true,
    Cell: ({ cell }) => (
      <GeneralPractitionerListServiceArea
        serviceArea={cell.row.values.serviceArea}
        specificServiceAreas={cell.row.values.specificServiceAreas}
      />
    )
  },
  status: {
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.status"
        defaultMessage="Staatus"
      />
    ),
    accessor: "status",
    disableSortBy: true,
    width: 0,
    Cell: ({ value }) => <GeneralPractitionerListStatus status={value} />
  },
  details: {
    Header: "",
    accessor: "id",
    sticky: true,
    disableSortBy: true,
    Cell: ({ value: id }) => (
      <DetailViewLinkButton
        id="lookCloserLink"
        to={`/general-practitioner-lists/${id}`}
      />
    ),
    width: 0
  },
  maxPatientsSize: {
    id: "maxPatientsSize",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.actualPatientsSize"
        defaultMessage="Suurus"
      />
    ),
    accessor: "maxPatientsSize",
    width: 0
  },
  actualVacanciesSize: {
    id: "vacanciesSize",
    Header: (
      <FormattedMessage
        id="GPListSearchTableHeader.vacanciesSize"
        defaultMessage="Vabu kohti"
      />
    ),
    accessor: "vacanciesSize",
    width: 0
  }
};

export const generalPractitionerListOfficialColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    generalPractitionerListColumnsObj.generalPractitioner,
    generalPractitionerListColumnsObj.substitute,
    generalPractitionerListColumnsObj.actualPatientsSize,
    generalPractitionerListColumnsObj.activationDate,
    generalPractitionerListColumnsObj.serviceArea,
    generalPractitionerListColumnsObj.status,
    {
      ...generalPractitionerListColumnsObj.details,
      accessor: "id",
      Cell: ({ value: id }) => (
        <DetailViewLinkButton to={`/general-practitioner-lists/${id}`} />
      )
    }
  ];

export const generalPractitionerListPortalColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    generalPractitionerListColumnsObj.generalPractitioner,
    generalPractitionerListColumnsObj.substitute,
    generalPractitionerListColumnsObj.actualPatientsSize,
    generalPractitionerListColumnsObj.details
  ];

const HealthCareEmployeeLinkCell = ({
  value
}: {
  value: GeneralPractitioner | Substitute;
}) =>
  value.id ? (
    <Link to={`/employees/${value.id}`}>{getGPDoctorLabel(value)}</Link>
  ) : null;

export const generalPractitionerListPublicPortalMobileColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    {
      id: "doctor",
      accessor: ({ generalPractitioner, substitute }) =>
        (generalPractitioner?.id || substitute?.id) && (
          <>
            <div className="font-size-14">
              {generalPractitioner?.id &&
                generalPractitionerListColumnsObj.generalPractitioner.Header}
              {substitute?.id &&
                generalPractitionerListColumnsObj.substitute.Header}
            </div>
            <span className="font-weight-500">
              {generalPractitioner?.id && getFullName(generalPractitioner)}
              {substitute?.id && getFullName(substitute)}
            </span>
          </>
        )
    },
    {
      accessor: "actualPatientsSize",
      Cell: ({ value }) => (
        <>
          <div className="font-size-14">
            {generalPractitionerListColumnsObj.actualPatientsSize.Header}
          </div>
          <span className="font-weight-500">{value}</span>
        </>
      )
    },
    {
      accessor: "id",
      id: "vacanciesSize",
      Cell: ({ cell }) => (
        <>
          <div className="font-size-14">
            {generalPractitionerListColumnsObj.vacanciesSize.Header}
          </div>
          <span className="font-weight-500">
            <GeneralPractitionerListVacanciesSize
              vacanciesSize={cell.row.original.vacanciesSize}
            />
          </span>
        </>
      )
    },
    generalPractitionerListColumnsObj.details
  ];

export const generalPractitionerListPublicPortalColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    {
      ...generalPractitionerListColumnsObj.generalPractitioner,
      Cell: HealthCareEmployeeLinkCell
    },
    {
      ...generalPractitionerListColumnsObj.substitute,
      Cell: HealthCareEmployeeLinkCell
    },
    generalPractitionerListColumnsObj.actualPatientsSize,
    generalPractitionerListColumnsObj.vacanciesSize,
    generalPractitionerListColumnsObj.details
  ];

export const activityLicenseGPListColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    generalPractitionerListColumnsObj.generalPractitioner,
    generalPractitionerListColumnsObj.substitute,
    generalPractitionerListColumnsObj.serviceArea,
    generalPractitionerListColumnsObj.maxPatientsSize,
    generalPractitionerListColumnsObj.actualVacanciesSize,
    {
      ...generalPractitionerListColumnsObj.details,
      accessor: "id",
      Cell: ({ value: id }) => (
        <DetailViewLinkButton
          id="look"
          to={`/general-practitioner-lists/${id}`}
        />
      )
    }
  ];

export const activityLicenseGPListMobileColumns: ReactTableColumn<GeneralPractitionerList>[] =
  [
    generalPractitionerListColumnsObj.code,
    {
      id: "doctor",
      accessor: ({ generalPractitioner, substitute }) =>
        (generalPractitioner?.id || substitute?.id) && (
          <>
            <div className="font-size-14">
              {generalPractitioner?.id && (
                <>
                  <div>
                    {
                      generalPractitionerListColumnsObj.generalPractitioner
                        .Header
                    }
                  </div>
                  <div className="font-weight-500">
                    {getGPDoctorLabel(generalPractitioner)}
                  </div>
                </>
              )}
            </div>
            <div className="font-size-14">
              {substitute?.id && (
                <>
                  <div>
                    {generalPractitionerListColumnsObj.substitute.Header}
                  </div>
                  <div className="font-weight-500">
                    {substitute?.id && getFullName(substitute)}
                  </div>
                </>
              )}
            </div>
          </>
        )
    },
    {
      accessor: "serviceArea",
      Cell: ({ cell }) => (
        <>
          <div className="font-size-14">
            {generalPractitionerListColumnsObj.serviceArea.Header}
          </div>
          <GeneralPractitionerListServiceArea
            serviceArea={cell.row.values.serviceArea}
            specificServiceAreas={cell.row.values.specificServiceAreas}
            className="font-weight-500 font-size-14"
          />
        </>
      )
    },
    {
      accessor: "maxPatientsSize",
      Cell: ({ value }) => (
        <>
          <div className="font-size-14">
            {generalPractitionerListColumnsObj.maxPatientsSize.Header}
          </div>
          <span className="font-weight-500">{value}</span>
        </>
      )
    },
    {
      accessor: "vacanciesSize",
      Cell: ({ value }) => (
        <>
          <div className="font-size-14">
            {generalPractitionerListColumnsObj.vacanciesSize.Header}
          </div>
          <span className="font-weight-500">{value}</span>
        </>
      )
    },
    {
      ...generalPractitionerListColumnsObj.details,
      accessor: "id",
      Cell: ({ value: id }) => (
        <DetailViewLinkButton
          id="look"
          to={`/general-practitioner-lists/${id}`}
        />
      )
    }
  ];