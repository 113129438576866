import { createSelector } from "reselect";
import { RootAction, RootState } from "../../../../rootReducer";
import { Option } from "../../../../Component/Select/SelectTypes";
import {
  SpecialityListItem,
  CountryListItem,
  ActiveFilters,
  ApplicantFilters,
  ApplicationFilters,
  ActivityLicenseApplicationFilters,
  ApplicationCertificateFilters,
  CompanyFilters,
  GeneralPractitionerListFilters,
  PublicGeneralPractitionerListFilters,
  PublicActivityLicenseFilters,
  PublicHealthcareEmployeesFilters,
  DecisionTHTFilters,
  DecisionLicenseFilters,
  InsuranceContractFilters
} from "./FilterTypes";
import { personStatuses } from "./FilterUtils";
import {
  ApplicantFilterState,
  ApplicationFilterState,
  ActivityLicenseApplicationFilterState,
  activityLicenseApplicationResetValues,
  ApplicationCertificateFilterState,
  CompanyFilterState,
  GeneralPractitionerListFilterState,
  generalPractitionerListResetValues,
  PublicGeneralPractitionerListFilterState,
  publicGeneralPractitionerListResetValues,
  ActivityLicensesFilterState,
  PublicHealthcareEmployeesFilterState,
  publicHealthcareEmployeesResetValues,
  activityLicenseResetValues,
  DecisionTHTFilterState,
  DecisionLicenseFilterState,
  decisionTHTResetValues,
  decisionLicenseResetValues,
  InsuranceContractFilterState,
  insuranceContractResetValues,
  OfficialActivityLicensesFilterState,
  officialActivityLicenseResetValues
} from "./filterActions";
import * as actions from "./filterActions";
import { ActivityLicenseApplicationStatus, InsuranceContractStatus } from "../../../../../api_client/medre_api";
import moment from "moment";

export class FilterOptionsState {
  allOccupations: Option[];
  allSpecialities: SpecialityListItem[];
  filteredSpecialities: Option[];
  allCountries: CountryListItem[];
  filteredCountries: Option[];
  filterType: ActiveFilters;
  applicantFilters: ApplicantFilterState;
  applicationFilters: ApplicationFilterState;
  activityLicenseApplicationFilters: ActivityLicenseApplicationFilterState;
  applicationCertificateFilters: ApplicationCertificateFilterState;
  companyFilters: CompanyFilterState;
  generalPractitionerListFilters: GeneralPractitionerListFilterState;
  publicGeneralPractitionerListFilters: PublicGeneralPractitionerListFilterState;
  activityLicenseFilters: ActivityLicensesFilterState;
  officialActivityLicenseFilters: OfficialActivityLicensesFilterState;
  publicHealthcareEmployeesFilters: PublicHealthcareEmployeesFilterState;
  decisionTHTFilters: DecisionTHTFilterState;
  decisionLicenceFilters: DecisionLicenseFilterState;
  insuranceContractFilters: InsuranceContractFilterState;

  constructor() {
    this.allOccupations = [];
    this.allSpecialities = [];
    this.filteredSpecialities = [];
    this.allCountries = [];
    this.filteredCountries = [];
    this.applicantFilters = {
      personStatus: personStatuses[0],
      occupationCode: "",
      occupations: [],
      specialities: [],
      name: "",
      idCodeCountry: null,
      idCode: "",
      dateOfBirth: undefined,
      sex: null,
      citizenship: null,
      countryGroup: null,
      countries: []
    };
    this.filterType = ActiveFilters.Applicant;
    this.applicationFilters = {
      currentOccupations: [],
      currentSpecialities: [],
      currentCountries: [],
      currentApplicationNumber: null,
      currentStatuses: [],
      stateFee: null,
      currentCountryGroup: null,
      timePeriod: null,
      currentStartDate: undefined,
      currentEndDate: undefined,
      decisionStatuses: []
    };
    this.applicationCertificateFilters = {
      timePeriod: null,
      currentStartDate: undefined,
      currentEndDate: undefined
    };
    this.companyFilters = {
      companyName: "",
      registryCode: "",
      representativeName: ""
    };
    this.generalPractitionerListFilters = generalPractitionerListResetValues;
    this.publicGeneralPractitionerListFilters =
      publicGeneralPractitionerListResetValues;
    this.publicHealthcareEmployeesFilters =
      publicHealthcareEmployeesResetValues;
    this.activityLicenseFilters = activityLicenseResetValues;
    this.officialActivityLicenseFilters = officialActivityLicenseResetValues;
    this.activityLicenseApplicationFilters =
      activityLicenseApplicationResetValues;
    this.decisionTHTFilters = decisionTHTResetValues;
    this.decisionLicenceFilters = decisionLicenseResetValues;
    this.insuranceContractFilters = insuranceContractResetValues;
  }
}

export const filterOptionsReducer = (
  state = new FilterOptionsState(),
  filterAction: RootAction
) => {
  const newState = { ...state };
  switch (filterAction.type) {
    case actions.SET_OCCUPATIONS:
      newState.allOccupations = filterAction.payload;
      return newState;
    case actions.SET_SPECIALITIES:
      newState.allSpecialities = filterAction.payload;
      return newState;
    case actions.SET_FILTERED_SPECIALITIES:
      newState.filteredSpecialities = filterAction.payload;
      return newState;
    case actions.SET_FILTER_COUNTRIES:
      newState.allCountries = filterAction.payload;
      return newState;
    case actions.SET_FILTERED_COUNTRIES:
      newState.filteredCountries = filterAction.payload;
      return newState;
    case actions.SET_APPLICANT_FILTER:
      newState.applicantFilters = {
        ...newState.applicantFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_APPLICANT_FILTERS:
      newState.applicantFilters = filterAction.payload;
      return newState;
    case actions.SET_FILTER_TYPE:
      let filterOptions = new FilterOptionsState();
      filterOptions.filterType = filterAction.payload;
      return filterOptions;
    case actions.SET_APPLICATION_FILTER:
      newState.applicationFilters = {
        ...newState.applicationFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_APPLICATION_FILTERS:
      newState.applicationFilters = filterAction.payload;
      return newState;
    case actions.SET_ACTIVITY_LICENSE_APPLICATION_FILTER:
      newState.activityLicenseApplicationFilters = {
        ...newState.activityLicenseApplicationFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_ACTIVITY_LICENSE_APPLICATION_FILTERS:
      newState.activityLicenseApplicationFilters = filterAction.payload;
      return newState;
    case actions.SET_APPLICATION_CERTIFICATE_FILTER:
      newState.applicationCertificateFilters = {
        ...newState.applicationCertificateFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_APPLICATION_CERTIFICATE_FILTERS:
      newState.applicationCertificateFilters = filterAction.payload;
      return newState;
    case actions.SET_COMPANY_FILTER:
      newState.companyFilters = {
        ...newState.companyFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_COMPANY_FILTERS:
      newState.companyFilters = filterAction.payload;
      return newState;
    case actions.SET_GENERAL_PRACTITIONER_LIST_FILTER: {
      newState.generalPractitionerListFilters = {
        ...newState.generalPractitionerListFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    }
    case actions.SET_ALL_GENERAL_PRACTITIONER_LIST_FILTERS:
      newState.generalPractitionerListFilters = filterAction.payload;
      return newState;
    case actions.SET_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTER: {
      newState.publicGeneralPractitionerListFilters = {
        ...newState.publicGeneralPractitionerListFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    }
    case actions.SET_ALL_PUBLIC_GENERAL_PRACTITIONER_LIST_FILTERS:
      newState.publicGeneralPractitionerListFilters = filterAction.payload;
      return newState;
    case actions.SET_PUBLIC_HEALTHCARE_EMPLOYEES_FILTER:
      newState.publicHealthcareEmployeesFilters = {
        ...newState.publicHealthcareEmployeesFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_PUBLIC_HEALTHCARE_EMPLOYEES_FILTERS:
      newState.publicHealthcareEmployeesFilters = filterAction.payload;
      return newState;

    case actions.SET_ACTIVITY_LICENSE_FILTER: {
      newState.activityLicenseFilters = {
        ...newState.activityLicenseFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    }
    case actions.SET_ALL_ACTIVITY_LICENSE_FILTERS:
      newState.activityLicenseFilters = filterAction.payload;
      return newState;

    case actions.SET_OFFICIAL_ACTIVITY_LICENSE_FILTER: {
      newState.officialActivityLicenseFilters = {
        ...newState.officialActivityLicenseFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    }
    case actions.SET_ALL_OFFICIAL_ACTIVITY_LICENSE_FILTERS: {
      newState.officialActivityLicenseFilters = filterAction.payload;
      return newState;
    }

    case actions.SET_DECISION_THT_FILTER:
      newState.decisionTHTFilters = {
        ...state.decisionTHTFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_DECISION_THT_FILTER:
      newState.decisionTHTFilters = filterAction.payload;
      return newState;

    case actions.SET_DECISION_LICENSE_FILTER:
      newState.decisionLicenceFilters = {
        ...state.decisionLicenceFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_DECISION_LICENSE_FILTER:
      newState.decisionLicenceFilters = filterAction.payload;
      return newState;
    case actions.SET_INSURANCE_CONTRACT_FILTER:
      newState.insuranceContractFilters = {
        ...state.insuranceContractFilters,
        [filterAction.payload.key]: filterAction.payload.data
      };
      return newState;
    case actions.SET_ALL_INSURANCE_CONTRACT_FILTERS:
      newState.insuranceContractFilters = filterAction.payload;
      return newState;
    default:
      return state;
  }
};

const baseSelectors = {
  selectAllCountries: (state: RootState) => state.filterOptions.allCountries,
  selectFilterType: (state: RootState) => state.filterOptions.filterType,
  selectApplicantFilters: (state: RootState) =>
    state.filterOptions.applicantFilters,
  selectApplicationFilters: (state: RootState) =>
    state.filterOptions.applicationFilters,
  selectActivityLicenseApplicationFilters: (state: RootState) =>
    state.filterOptions.activityLicenseApplicationFilters,
  selectApplicationCertificateFilters: (state: RootState) =>
    state.filterOptions.applicationCertificateFilters,
  selectCompanyFilters: (state: RootState) =>
    state.filterOptions.companyFilters,
  selectGeneralPractitionerListFilters: (state: RootState) =>
    state.filterOptions.generalPractitionerListFilters,
  selectPublicGeneralPractitionerListFilters: (state: RootState) =>
    state.filterOptions.publicGeneralPractitionerListFilters,
  selectPublicActivityLicenseFilters: (state: RootState) =>
    state.filterOptions.activityLicenseFilters,
  selectOfficialActivityLicenseFilters: (state: RootState) =>
    state.filterOptions.officialActivityLicenseFilters,
  selectPublicHealthcareEmployeesFilters: (state: RootState) =>
    state.filterOptions.publicHealthcareEmployeesFilters,
  selectDecisionTHTFilters: (state: RootState) =>
    state.filterOptions.decisionTHTFilters,
  selectDecisionLicenceFilters: (state: RootState) =>
    state.filterOptions.decisionLicenceFilters,
  selectInsuranceContractFilters: (state: RootState) =>
    state.filterOptions.insuranceContractFilters
};

const getValue = (option: Option) => option.value;
const getRelatedApplicationStatuses = (applicationStatus?: ActivityLicenseApplicationStatus) => {
  if (!applicationStatus) {
    return [];
  }

  switch (applicationStatus) {
    case ActivityLicenseApplicationStatus.Submitted:
      return [
        ActivityLicenseApplicationStatus.Submitted,
        ActivityLicenseApplicationStatus.SubmittedByOfficial
      ];
    case ActivityLicenseApplicationStatus.InProceeding:
      return [
        ActivityLicenseApplicationStatus.InProceeding,
        ActivityLicenseApplicationStatus.ProceedingReopened,
        ActivityLicenseApplicationStatus.SignReopened
      ];
    case ActivityLicenseApplicationStatus.UploadAcceptedDecision:
      return [
        ActivityLicenseApplicationStatus.UploadAcceptedDecision,
        ActivityLicenseApplicationStatus.UploadDeclinedDecision
      ];
    default:
      return [applicationStatus];
  }
};

export const filterSelectors = {
  selectAllCountryOptions: createSelector(
    baseSelectors.selectAllCountries,
    (countries: CountryListItem[]) => countries.map((country) => country.option)
  ),
  selectApplicantFilterValues: createSelector(
    baseSelectors.selectApplicantFilters,
    (filters: ApplicantFilterState): ApplicantFilters => {
      return {
        name: filters.name,
        idCodeCountry: filters.idCodeCountry?.value || "",
        idCode: filters.idCode,
        dateOfBirth: filters.dateOfBirth
          ? moment(filters.dateOfBirth).format("YYYY-MM-DD")
          : undefined,
        sex: filters.sex?.value || null,
        citizenship: filters.citizenship?.value || "",
        status: filters.personStatus?.value || null,
        occupationCode: filters.occupationCode,
        occupationNames: filters.occupations?.map(getValue) || [],
        specialityCodes: filters.specialities?.map(getValue) || [],
        educationCountryGroup: filters.countryGroup?.value || null,
        educationCountries: filters.countries?.map(getValue) || []
      };
    }
  ),
  selectApplicationFilterValues: createSelector(
    baseSelectors.selectApplicationFilters,
    (filters: ApplicationFilterState): ApplicationFilters => {
      return {
        occupationNames: filters.currentOccupations?.map(getValue) || [],
        specialityCodes: filters.currentSpecialities?.map(getValue) || [],
        applicationNumber: filters.currentApplicationNumber || null,
        currentStatuses: filters.currentStatuses?.map(getValue) || [],
        educationCountryGroup: filters.currentCountryGroup?.value || null,
        educationCountries: filters.currentCountries?.map(getValue) || [],
        stateFeePaid: filters.stateFee
          ? filters.stateFee.value === "true"
          : null,
        submitDate: {
          start: filters.currentStartDate
            ? moment(filters.currentStartDate).format("YYYY-MM-DD")
            : undefined,
          end: filters.currentEndDate
            ? moment(filters.currentEndDate).format("YYYY-MM-DD")
            : undefined
        },
        decisionStatuses: filters.decisionStatuses?.map(getValue) || [],
        acceptedAutomatically: filters.acceptedAutomatically,
        decisionNumber: filters.currentDecisionNumber,
        onlyApplicationCertificate: filters.onlyApplicationCertificate
      };
    }
  ),
  selectActivityLicenseApplicationFilterValues: createSelector(
    baseSelectors.selectActivityLicenseApplicationFilters,
    (
      filters: ActivityLicenseApplicationFilterState
    ): ActivityLicenseApplicationFilters => {
      return {
        businessName: filters.businessName,
        businessAreaIds: filters.businessAreaIds?.map(getValue) || [],
        applicationStatuses: getRelatedApplicationStatuses(
          filters.applicationStatus
        ),
        proceedingOfficialId: filters.proceedingOfficial,
        applicationNumber: filters.applicationNumber || null,
        hospitalTypeIds: filters.hospitalTypeIds?.map(getValue) || [],
        submitDate: {
          start: filters.currentStartDate
            ? moment(filters.currentStartDate).format("YYYY-MM-DD")
            : undefined,
          end: filters.currentEndDate
            ? moment(filters.currentEndDate).format("YYYY-MM-DD")
            : undefined
        },
        proceedingType: filters.proceedingType
      };
    }
  ),
  selectApplicationCertificateFilterValues: createSelector(
    baseSelectors.selectApplicationCertificateFilters,
    (filters: ApplicationFilterState): ApplicationCertificateFilters => {
      return {
        issueDate: {
          start: filters.currentStartDate
            ? moment(filters.currentStartDate).format("YYYY-MM-DD")
            : undefined,
          end: filters.currentEndDate
            ? moment(filters.currentEndDate).format("YYYY-MM-DD")
            : undefined
        }
      };
    }
  ),
  selectCompanyFilterValues: createSelector(
    baseSelectors.selectCompanyFilters,
    (filters: CompanyFilterState): CompanyFilters => {
      return {
        companyName: filters.companyName,
        registryCode: filters.registryCode,
        representativeName: filters.representativeName
      };
    }
  ),
  selectGeneralPractitionerListFilterValue: createSelector(
    baseSelectors.selectGeneralPractitionerListFilters,
    (
      filters: GeneralPractitionerListFilterState
    ): GeneralPractitionerListFilters => {
      return {
        code: filters?.code,
        activationDate: filters.activationDate
          ? moment(filters.activationDate).format("YYYY-MM-DD")
          : undefined,
        gpOccupationCode: filters.generalPractitioner?.occupationCode,
        substituteOccupationCode: filters.substitute?.occupationCode,
        serviceAreaValue: filters?.serviceArea,
        status: filters.status?.value
      };
    }
  ),
  selectPublicGeneralPractitionerListFilterValue: createSelector(
    baseSelectors.selectPublicGeneralPractitionerListFilters,
    (
      filters: PublicGeneralPractitionerListFilterState
    ): PublicGeneralPractitionerListFilters => {
      return {
        gpSearchCode: filters?.generalPractitionerOrSubstitute,
        companyName: filters?.companyName,
        serviceAreaValue: filters?.serviceArea,
        onlyWithVacancies: filters?.onlyWithVacancies,
        code: filters?.code
      };
    }
  ),
  selectActivityLicensesFilterValue: createSelector(
    baseSelectors.selectPublicActivityLicenseFilters,
    (filters: ActivityLicensesFilterState): PublicActivityLicenseFilters => {
      return {
        ...filters,
        insuranceContractStatuses: filters.insuranceContractStatuses === InsuranceContractStatus.Valid
          ? [filters.insuranceContractStatuses]
          : filters.insuranceContractStatuses === InsuranceContractStatus.None
            ? [null, InsuranceContractStatus.Future, InsuranceContractStatus.Expired]
            : []
      };
    }
  ),
  selectOfficialActivityLicensesFilterValue: createSelector(
    baseSelectors.selectOfficialActivityLicenseFilters,
    (filters: OfficialActivityLicensesFilterState) => {
      return {
        ...filters,
        serviceIds: filters.serviceIds?.map(getValue),
        businessAreaIds: filters.businessAreaIds?.map(getValue),
        insuranceContractStatuses: filters.insuranceContractStatuses?.map(getValue)
          .map(item => item === InsuranceContractStatus.None ? null : item) || []
      };
    }
  ),
  selectPublicHealthcareEmployeesFilterValue: createSelector(
    baseSelectors.selectPublicHealthcareEmployeesFilters,
    (
      filters: PublicHealthcareEmployeesFilterState
    ): PublicHealthcareEmployeesFilters => {
      return {
        occupationCode: filters?.occupationCode,
        firstName: filters?.firstName,
        lastName: filters?.lastName,
        occupation: filters?.occupation,
        speciality: filters?.speciality
      };
    }
  ),
  selectDecisionTHTFilterValue: createSelector(
    baseSelectors.selectDecisionTHTFilters,
    (filters: DecisionTHTFilterState): DecisionTHTFilters => ({
      ...filters,
      decisionSearchStatuses: filters.decisionSearchStatuses.map(
        ({ value }) => value
      )
    })
  ),
  selectDecisionLicenseFilterValue: createSelector(
    baseSelectors.selectDecisionLicenceFilters,
    (filters: DecisionLicenseFilterState): DecisionLicenseFilters => ({
      ...filters,
      decisionSearchStatuses: filters.decisionSearchStatuses.map(
        ({ value }) => value
      )
    })
  ),
  selectInsuranceContractFilterValues: createSelector(
    baseSelectors.selectInsuranceContractFilters,
    (filters: InsuranceContractFilterState): InsuranceContractFilters => {
      return {
        policyHolder: filters.policyHolder,
        insurer: filters.insurer,
        number: filters.contractNumber.trim() === "" ? null : filters.contractNumber,
        status: filters.status?.map(getValue) || [],
        periodStartDate: filters.periodStartDate ?
          moment(filters.periodStartDate).format("YYYY-MM-DD") : undefined,
        periodEndDate: filters.periodEndDate ?
          moment(filters.periodEndDate).format("YYYY-MM-DD") : undefined,
        insuranceSumFrom: filters.insuranceSumFrom,
        insuranceSumTo: filters.insuranceSumTo,
        periodStartDateOperator: filters.periodStartDateOperator,
        periodEndDateOperator: filters.periodEndDateOperator
      };
    }
  ),

  ...baseSelectors
};
